import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import { AppMode, NameplateMaterialType, useCommonState, useNameplateSettings } from '@/hooks';
import { Header } from './components/Header';
import { DareToBeDifferent } from './components/DareToBeDifferent';
import { TellsStory } from './components/TellsStory';
import { MoreThanGift } from './components/MoreThanGift';
import { WhyChooseUs } from './components/WhyChooseUs';
import { HowItWorks } from './components/HowItWorks';
import { Footer } from './components/Footer';
import { cloudinaryImages } from '@/utils/cloudinary';
import { duration, useMediaQuery } from '@mui/material';
import { motion } from 'framer-motion';
import { Logo } from '@/components/UI/Logo';
import LogoIcon from '@assets/images/icons/common/logo.png';
import LogoText from '@assets/images/icons/common/logo-text.png';
import { Container } from './components/Container';
import { MobileConstructor } from './components/MobileConstructor';
import { NewConstructor } from './components/NewConstructor';
import { SubscriptionPopup } from './components/SubscriptionPopup';
import { MobileSubscriptionPopup } from './components/MobileSubscriptionPopup';
import { useLocation } from 'react-router-dom';

export const LandingPage: React.FC = () => {
  const location = useLocation();

  // Extract query parameters
  const searchParams = new URLSearchParams(location.search);
  const { onChangeText, onSelectMaterial } = useNameplateSettings();

  const { appMode, onChangeAppMode } = useCommonState();
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const [isShowSubPopup, setIsShowSubPopup] = useState(0);

  const isConstructor = appMode === AppMode.CONSTRUCTOR;

  const bgPreviewImage = isMobile
    ? cloudinaryImages.landing.images.bgPreviewConstructorMobile
    : cloudinaryImages.landing.images.bgPreviewConstructorDesktop;


  const onClosePopup = () => {
    localStorage.setItem("popupShown", "true")
    setIsShowSubPopup(2)
  }

  useEffect(() => {
    if (isMobile) {
      const html = document.querySelector('html');
      if (isConstructor) {
        if (html) {
          html.style.overflow = 'hidden';
        }
      } else {
        if (html) {
          html.style.overflow = 'auto';
        }
      }
    }
    const popupShown = localStorage.getItem("popupShown1")
    if (popupShown != "true" && isShowSubPopup == 0) {
      setTimeout(() => {
        setIsShowSubPopup(1)
      }, 7000)
    }
  })

  const isValidEnglishAlphabet = (str: string) => /^[a-zA-Z]+$/.test(str);


  useEffect(() => {
    const nameplateText = searchParams.get('nameplateText'); // Replace 'key' with your query parameter name
    const nameplateMaterial = searchParams.get('nameplateMaterial'); // Replace 'key' with your query parameter name

    if (nameplateText) {
      const isValid = isValidEnglishAlphabet(nameplateText)
      if (isValid) {
        onChangeText(nameplateText)
        onChangeAppMode(AppMode.CONSTRUCTOR)
      }
    }
    if (nameplateMaterial) {
      if (nameplateMaterial === "gold") {
        onSelectMaterial(NameplateMaterialType.GOLD)
      } else if (nameplateMaterial === "silver") {
        onSelectMaterial(NameplateMaterialType.SILVER)
      } else if (nameplateMaterial === "rose") {
        onSelectMaterial(NameplateMaterialType.ROSE_GOLD)
      }
    }
  }, [])


  return (
    <div
      className={cls({
        'h-[100svh]': isConstructor,
        // 'h-[100vh]': isMobile && isConstructor,
        // 'h-[100vh]': !isMobile && isConstructor
      })}
    >
      {isMobile ?
        <motion.div
          initial={false}
          style={{ position: 'absolute', left: '24px', top: '2rem', zIndex: 1003 }}
          animate={isConstructor ? { top: -100, transition: { duration: 0.4 } } : { top: '1.5rem', transition: { duration: 0.4 } }}
        >
          <img src={LogoIcon} alt='two-in-town' className='w-[26.8px] md:w-[32.25px] mr-2' />
          <img src={LogoText} alt='logo-text' className='pb-2 w-[134.76px] md:w-[168.75px] md:block' />
        </motion.div>
        :
        <motion.header
          className='absolute top-0 z-[1001] w-[90%] right-[10%]'
        >
          <Container>
            <div className='flex py-6 bg-transparent'>
              <div className='flex items-center grow gap-x-3 pl-0'>
                <Logo />
              </div>
            </div>
          </Container>
        </motion.header>
      }

      <motion.div
        style={{ position: 'absolute', y: 0, zIndex: 1003, x: 0, width: '100%' }}
        animate={isConstructor ?
          isMobile ?
            {
              y: -150,
              transition: { duration: 0.3 }
            }
            :
            {
              y: -150,
              transition: { duration: 0.8 }
            }
          :
          isMobile ?
            {
              height: 100,
              y: 0,
              transition: { duration: 0.4 }
            }
            :
            {
              height: 100,
              y: 0,
              transition: { duration: 0.8 }
            }
        }
      >
        <Header />
      </motion.div>
      <motion.div
      >
      {isShowSubPopup == 10 && (isMobile ? <MobileSubscriptionPopup onClose={onClosePopup}/> : <SubscriptionPopup onClose={onClosePopup} />)}
      </motion.div>
      <div
        style={{
          background: 'linear-gradient(0deg, rgb(252, 240, 234, 1) 30%, rgba(253, 247, 244, 1) 100%)',
          opacity: 1,
          visibility: "visible"
        }}
        className={cls(
          'relative overflow-hidden transition-all duration-1000 delay-500 z-30',
          {
            'visible opacity-1': !isConstructor,
            'invisible opacity-0 h-[100vh]': isConstructor,
          },
        )}
      >
        <div
          style={{
            backgroundSize: 'cover',
            backgroundImage: `url('${bgPreviewImage}')`,
            backgroundRepeat: 'no-repeat',
            position: 'absolute',
            maxHeight: '1907px',
            zIndex: -20,
            height: '100%',
            width: '100%',
          }}
        />
        {!isMobile ? <NewConstructor /> : <MobileConstructor />}
        <DareToBeDifferent />
        <TellsStory />
        <MoreThanGift />
        <WhyChooseUs />
        <HowItWorks />
        <Footer />
      </div>
    </div>
  );
};
