import * as PIXI from 'pixi.js';
import { NameplateCanvasApp } from './NameplateCanvasApp';
import { canvasToSvg, scaleSvgPath, strokeBlueImage, strokeImage, SvgExportData } from '../common/canvasToSvg';
import { NAMEPLATE_TO_PENDANT_SCALE } from './NameplateCanvasAppLayout';
import {
  NameplateMaterialType,
  NameplateSizeType,
} from '../../hooks';
import { RulerUnits } from '../../hooks';
import { addWhiteBg } from '../common/addWhiteBg';
import { drawCropVariant, drawNameplateArea } from '../common/drawPendantArea';
import { CropVariant } from './backgrounds';

export class NameplateCanvasAppExporter {
  private static app: NameplateCanvasApp | null;

  static setApp(app: NameplateCanvasApp) {
    NameplateCanvasAppExporter.app = app;
  }

  static async export(): Promise<{
    previewBase64: string;
    pendant: SvgExportData;
    pngPendant: string;
    previewWithoutChain: string;
    // previewVariants: any;
  } | null> {
    if (!this.app) {
      return null;
    }

    const app = this.app;

    const previewBase64 = this.execOnContainer(
      this.app,
      app.layout.pendantContainer,
      this.generatePreviewBase64,
    );

    const pendant = this.execOnContainer(
      this.app,
      app.layout.labelContainer,
      this.generateStrokedPendant,
    );

    const previewWithoutChainBase64 = this.execOnContainer2(
      this.app,
      app.layout.pendantContainer,
      this.generateBlueStrokedPendant,
    );

    // const canvas: HTMLCanvasElement = this.app.renderer.plugins.extract.canvas(this.app.layout.labelContainer);
    //   console.log("canvas = ", canvas.toDataURL())
    // this.generateBlueStrokedPendant(this.app, app.layout.pendantContainer)
    

    const pendantData = await canvasToSvg(pendant);
    {
      const widthMm = app.layout.label.getWidthMm();
      const svgScale = widthMm / pendantData.bounds.width;

      scaleSvgPath(pendantData, svgScale);
    }
    // const previewVariants = await this.generatePreviewVariants();


    const pngNameplate = pendant.toDataURL()

    return {
      previewBase64,
      pendant: pendantData,
      pngPendant: pngNameplate,
      previewWithoutChain: previewWithoutChainBase64.toDataURL()
      // previewVariants,
    };
  }

  private static generateStrokedPendant(
    app: NameplateCanvasApp,
    pendantContainer: PIXI.Container,
  ): HTMLCanvasElement {
    app.layout.chainsContainer.visible = false;
    const canvas = app.renderer.plugins.extract.canvas(pendantContainer);
    app.layout.chainsContainer.visible = true;

    return strokeImage(canvas);
  }

  private static generateBlueStrokedPendant(
    app: NameplateCanvasApp,
    pendantContainer: PIXI.Container,
  ): HTMLCanvasElement {

    const fullCanvas: HTMLCanvasElement = app.renderer.plugins.extract.canvas(pendantContainer);

    const rendererCanvas = fullCanvas;
    const labelContBo = app.layout.labelContainer.getBounds()
    const pendantContBo = app.layout.pendantContainer.getBounds()

    // Define the crop area
    const cropX = labelContBo.x - pendantContBo.x; // X-coordinate where cropping starts
    const cropY = pendantContainer.height - app.layout.labelContainer.height; // Y-coordinate where cropping starts
    const cropWidth = app.layout.labelContainer.width; // Width of the crop area
    const cropHeight = app.layout.labelContainer.height; // Height of the crop area

    // Create a new canvas for the cropped image
    const croppedCanvas = document.createElement('canvas');
    croppedCanvas.width = cropWidth;
    croppedCanvas.height = cropHeight;
    const context = croppedCanvas.getContext('2d')!;

    // Draw the cropped portion of the original canvas onto the new canvas
    context.drawImage(rendererCanvas, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight);
    return croppedCanvas
  }

  private static generatePreviewBase64(
    app: NameplateCanvasApp,
    pendantContainer: PIXI.Container,
  ): string {
    const scale = app.layout.pendantContainer.scale.x;
    app.layout.pendantContainer.scale.set(1);

    const base64 = app.renderer.plugins.extract.base64(pendantContainer);

    app.layout.pendantContainer.scale.set(scale);

    return base64;
  }

  private static execOnContainer<T>(
    app: NameplateCanvasApp,
    root: PIXI.Container,
    cb: (app: NameplateCanvasApp, container: PIXI.Container) => T,
  ): T {
    const parent = root.parent;
    const index = parent.getChildIndex(root);

    const rootScale = root.scale.clone();
    root.scale.set(NAMEPLATE_TO_PENDANT_SCALE);

    const container = new PIXI.Container();
    container.addChild(root);

    const res = cb(app, container);

    root.scale.copyFrom(rootScale);
    parent.addChildAt(root, index);

    return res;
  }

  private static execOnContainer2<T>(
    app: NameplateCanvasApp,
    root: PIXI.Container,
    cb: (app: NameplateCanvasApp, container: PIXI.Container) => T,
  ): T {
    const parent = root.parent;
    const index = parent.getChildIndex(root);

    const rootScale = root.scale.clone();
    root.scale.set(1);

    const container = new PIXI.Container();
    container.addChild(root);

    const res = cb(app, container);

    root.scale.copyFrom(rootScale);
    parent.addChildAt(root, index);

    return res;
  }

  private static async generatePreviewVariants() {
    const app = this.app;

    if (!app) return;

    const props = app.layout.props!;
    app.layout.controller.deactivate();

    const result: any = {};

    // 1. white bg Gold and Silver
    {
      app.layout.bg.slicesContainer.alpha = 0;

      for (const material of [NameplateMaterialType.GOLD, NameplateMaterialType.SILVER]) {
        await app.update({
          ...props,
          zoom: 0.5,
          label: { ...props.label, materialType: material },
          rulerIsEnabled: false,
        });

        const canvas = drawNameplateArea(app);
        const whiteCanvas = addWhiteBg(canvas);

        result[`whiteBg${material}`] = whiteCanvas.toDataURL();
      }

      app.layout.bg.slicesContainer.alpha = 1;
    }

    // 2a. All sizes with ruler Gold and Silver
    {
      const possibleSizes = Object.values(NameplateSizeType) as NameplateSizeType[];

      for (const material of [NameplateMaterialType.GOLD, NameplateMaterialType.SILVER]) {
        for (const size of possibleSizes) {
          await app.update({
            ...props,
            zoom: 0.5,
            label: { ...props.label, materialType: material },
            pendantSize: size,
            rulerMode: RulerUnits.CM,
            rulerIsEnabled: true,
          });

          const canvas = drawCropVariant(app, props.backgroundName, CropVariant.Middle);
          result[`withRuler${material}${size}`] = canvas.toDataURL();
        }
      }
    }

    // // 1b. white bg Gold and Silver Full chain
    // {
    //   app.layout.bg.slicesContainer.visible = false;
    //
    //   for (const material of [NameplateMaterialType.GOLD, NameplateMaterialType.SILVER]) {
    //     await app.update({
    //       ...props,
    //       zoom: 0.5,
    //       label: { ...props.label, materialType: material },
    //       rulerIsEnabled: false,
    //     });
    //
    //     const canvas = app.renderer.plugins.extract.canvas(app.stage);
    //     const whiteCanvas = addWhiteBg(canvas);
    //
    //     result[`whiteBgFullChain${material}`] = whiteCanvas.toDataURL();
    //   }
    //
    //   app.layout.bg.slicesContainer.visible = true;
    // }

    // 2b. Size M without ruler Gold and Silver
    {
      const possibleSizes = Object.values(NameplateSizeType) as NameplateSizeType[];

      for (const material of [NameplateMaterialType.GOLD, NameplateMaterialType.SILVER]) {
        for (const size of possibleSizes) {
          await app.update({
            ...props,
            zoom: 0.5,
            label: { ...props.label, materialType: material },
            pendantSize: size,
            rulerIsEnabled: false,
          });

          const canvas = drawCropVariant(app, props.backgroundName, CropVariant.Big);
          result[`portrait${material}${size}`] = canvas.toDataURL();
        }
      }
    }

    // // 3. Gold and silver cut model head
    // {
    //   for (const material of [NameplateMaterialType.GOLD, NameplateMaterialType.SILVER]) {
    //     await app.update({
    //       ...props,
    //       zoom: 0.5,
    //       label: { ...props.label, materialType: material },
    //       pendantSize: props.pendantSize,
    //       rulerIsEnabled: false,
    //     });
    //
    //     const canvas = drawCropVariant(app, props.backgroundName, CropVariant.Middle);
    //     result[`cutHead${material}`] = canvas.toDataURL();
    //   }
    // }

    await app.update(props);

    return result;
  }
}

// window.test = async () => {
//   const { previewVariants } = (await NameplateCanvasAppExporter.export())!;
//
//   Object.entries(previewVariants).forEach(([name, base64]) => {
//     const image = new Image();
//     image.src = base64 as string;
//
//     const div = document.createElement('div');
//     div.innerText = name;
//     document.body.appendChild(div);
//     document.body.appendChild(image);
//     document.getElementById('root')!.style.visibility = 'hidden';
//   });
// };
