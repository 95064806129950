import React, { useEffect, useState } from 'react';

import { Canvas } from '../../components';

import { NameplateCanvasApp } from './NameplateCanvasApp';
import { useMediaQuery } from '@mui/material';

import {
  AppMode,
  useCommonState,
  useNameplateSettings,
  useResizeHandler,
} from '../../hooks';
import { BackgroundName } from './backgrounds';
import { NameplateMaterialType, NameplateSizeLimitState, NameplateSizeType } from '../../hooks/nameplate/use-nameplate-settings/types';
import backgroundSource from '@assets/images/backgrounds/model-4/model-4.jpg'
import axios from 'axios';
import { ThreeScene } from './ThreejsCanvasComponent';

let timeoutId: any;

// const nameplateApiUrl = "http://localhost:3334"
const nameplateApiUrl = "https://nameplategeneratorapi.twointown.com"

export const BasicNameplateCanvasComponent = () => {
  const { is3dViewEnabled } = useCommonState();

  const { rulerIsEnabled, rulerMode, zoom, appMode } = useCommonState();
  const {
    fontSize,
    letterSpacing,
    font,
    text,
    textDeformation,
    selectedMaterial,
    textSize,
    texturesLoading,
    chainSize,
    onChangeText,
    onChangeFontSize,
    onChangeArea,
    onChangeBaseArea,
    onChangeWidthInMM,
    onChangeHeightInMM,
    onChangeTexturesLoading,
    onChangeSizeLimitState,
    onChangeBaseHeightInMM,
    onChangeBaseWidthInMM,
    saveSnapshot,
  } = useNameplateSettings();
  const [maxWidth, setMaxWidth] = useState(58);
  const [isLimit, setIsLimitSize] = useState(false);

  useEffect(() => {

    // Debounce function to delay the function call
    const debounce = (func: any, delay: any) => {
      return function (...args: any) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func.apply(args), delay);
      };
    };

    // Function to fetch and display image
    async function fetchAndDisplayImage() {
      try {


        // // Fetch image data using Axios
        // const response = await axios.get(`${nameplateApiUrl}/?text=${text}&font=${font}&color=${selectedMaterial}&fontSize=${(fontSize / 5.75)}`, {
        //   responseType: 'blob' // Set responseType to 'arraybuffer' to get binary data
        // });

        // // Create object URL for the blob
        // const url = URL.createObjectURL(response.data);

        // // Set the received image URL
        // setImageUrl(url);


        await axios.get(`${nameplateApiUrl}/square?text=${text}&font=${font}&color=${selectedMaterial}&fontSize=${(5.75 / 5.75)}`).then(result => {
          // onChangeArea(result.data.totalArea)
          // onChangeWidthInMM(result.data.widthInMM)
          // onChangeHeightInMM(result.data.heightInMM)
          onChangeArea(result.data.totalArea)
          onChangeBaseArea(result.data.totalArea)
          onChangeWidthInMM(result.data.widthInMM)
          onChangeHeightInMM(result.data.heightInMM)
          onChangeBaseHeightInMM(result.data.heightInMM)
          onChangeBaseWidthInMM(result.data.widthInMM)
          const percent = result.data.widthInMM / maxWidth;
          const limitState =
            percent === Infinity
              ? NameplateSizeLimitState.Empty
              : percent > 1
                ? NameplateSizeLimitState.Exceeded
                : percent >= 0.8
                  ? NameplateSizeLimitState.Warn
                  : NameplateSizeLimitState.Allowed;

          onChangeSizeLimitState(limitState);
          if (limitState === NameplateSizeLimitState.Exceeded) {
            setIsLimitSize(true)
          } else {
            setIsLimitSize(false)
          }
        })

      } catch (error) {
        console.error('Error fetching image:', error);
      }
    }

    // Call the function to fetch and display the image
    // fetchAndDisplayImage();


    // Create a debounced function to delay the request
    const debouncedFetchImageData = debounce(fetchAndDisplayImage, 300); // Delay of 0.3 seconds

    // Call the debounced function to fetch image data
    debouncedFetchImageData();
    // // Clean up the timeout on unmount
    return () => {
      clearTimeout(timeoutId);
    };

  }, [font, text, fontSize, selectedMaterial])

  return (
    <div
      className={`relative left-[${100 - (window.innerWidth - 1625)}px] overflow-hidden bottom-[300px]`}
      // style={{ width: window.innerWidth }}
      style={{
        display: is3dViewEnabled ? 'none' : 'block',
        width: window.innerWidth, 
        transform: `scale(${zoom})`, 
        transformOrigin: `850px 30%`
      }}
    >
      <img src={backgroundSource} width={2100 + (window.innerWidth - 1625)} height={'100%'} ></img>
      {true && (
        <div style={{
          'width': '100%',
          'height': '100%',
          'position': 'absolute',
          // left: 100,
          top: 0,
          // backgroundColor: 'green'
        }}>
          <ThreeScene/>
        </div>
      )}
      {/* <img style={{position: 'absolute', left: 0, top: -100}}src={`http://localhost:3334/image?text=${text}&font=${font}&color=${selectedMaterial}&fontSize=${(fontSize/5.75)}`}></img> */}
    </div>

  );
};
