import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import studio_small_09_1k from '@assets/3dcomponents/studio_small_09_1k.png'
import cyclorama_hard_light_1k from '@assets/3dcomponents/cyclorama_hard_light_1k.png'
import {
    AppMode,
    NameplateMaterialType,
    RulerUnits,
    useCommonState,
    useNameplateSettings,
    useResizeHandler,
} from '../../hooks';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';


export default function ThreeDViewComponent() {
    const nameplatePosition = { x: 0, y: 0, z: 0 }
    const { is3dViewEnabled } = useCommonState();

    const windowWidth = window.innerWidth
    const windowHeight = window.innerHeight;

    const glRef = useRef(null);
    const glViewRef = useRef(null);
    const [camera, setCamera] = React.useState<THREE.PerspectiveCamera | null>(null);
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const {
        fontSize,
        letterSpacing,
        font,
        text,
        textDeformation,
        selectedMaterial,
        textSize,
        texturesLoading,
        chainSize,
        onChangeText,
        onChangeFontSize,
        onChangeArea,
        onChangeWidthInMM,
        onChangeHeightInMM,
        onChangeTexturesLoading,
        onChangeSizeLimitState,
        saveSnapshot,
        saveImages,
        widthInMM,
        heightInMM
    } = useNameplateSettings();


    useEffect(() => {
        onContextCreate()
    }, [text, selectedMaterial, font, fontSize, chainSize]); // Dependencies for re-render

    const onContextCreate = async () => {
        if (text.length <= 1){
            return
        }
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, windowWidth / windowHeight, 0.1, 1000);
        const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current!, alpha: true, antialias: true, preserveDrawingBuffer: true });
        renderer.setClearColor(0xffffff, 0);
        renderer.setSize(windowWidth, windowHeight);
        renderer.setPixelRatio(window.devicePixelRatio); // Match device pixel ratio
        renderer.shadowMap.enabled = true;
        camera.position.x = 0
        camera.position.y = 0
        camera.position.z = 30

        const directionalLight = new THREE.DirectionalLight(getLightColorByColor(selectedMaterial), 1); // Stronger and more direct light
        const lightPosition = getLightPositionByColor(selectedMaterial)
        directionalLight.position.set(lightPosition.x, lightPosition.y, lightPosition.z); // Slightly off-center
        directionalLight.castShadow = true;
        scene.add(directionalLight);

        const rgbeLoader = new RGBELoader();
        const silverHdriTexture = await rgbeLoader.loadAsync(cyclorama_hard_light_1k)
        const goldHdriTexture = await rgbeLoader.loadAsync(studio_small_09_1k)
        let hdriTexture = goldHdriTexture
        if (selectedMaterial == NameplateMaterialType.SILVER) {
            hdriTexture = silverHdriTexture
        }

        hdriTexture.mapping = THREE.EquirectangularReflectionMapping;
        hdriTexture.flipY = true;          // Set during creation
        hdriTexture.premultiplyAlpha = false; // Set during creation
        scene.environment = hdriTexture;


        const envPosition = getScenePositionByColor(selectedMaterial)
        scene.environmentRotation.x = envPosition.x
        scene.environmentRotation.y = envPosition.y
        scene.environmentRotation.z = envPosition.z

        const fontLoader = new FontLoader()
        const defaultFontPath = await fontLoader.loadAsync('fonts/helvetiker_regular.typeface.json');
        const jockyStarlineFontPath = await fontLoader.loadAsync('/fonts/JockyStarline_JSON.json');
        const anderlechtFontPath = await fontLoader.loadAsync('/fonts/Anderlecht_JSON.json');
        const lobsterFontPath = await fontLoader.loadAsync('/fonts/Lobster_JSON.json');
        // const anderlechtFontPath = await fontLoader.loadAsync('./Anderlecht_JSON.json')            
        // const jockyStarlineFontPath = await fontLoader.loadAsync('./Anderlecht_JSON.json')
        // const lobsterFontPath = await fontLoader.loadAsync('./Anderlecht_JSON.json')

        let fontPath = anderlechtFontPath;
        if (font === "Anderlecht") {
            fontPath = anderlechtFontPath;
        } else if (font === "JockyStarline") {
            fontPath = jockyStarlineFontPath;
        } else if (font === "Lobster") {
            fontPath = lobsterFontPath;
        }

        const textMeshGroup = await createTextGroup(fontPath)
        const textMesh = textMeshGroup.textMesh
        const leftRingMesh = textMeshGroup.leftRingMesh
        const rightRingMesh = textMeshGroup.rightRingMesh
        const leftCirclePosition = textMeshGroup.leftTextRingPosition;
        const rightCirclePosition = textMeshGroup.rightTextRingPosition;

        const group = new THREE.Group();
        group.add(textMesh);
        group.add(leftRingMesh);
        group.add(rightRingMesh);
        // group.rotation.y = Math.PI
        scene.add(group)

        const box = new THREE.Box3().setFromObject(textMesh);
        const size = box.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
        const width = size.x;
        const height = size.y;


        const textMaterial = new THREE.MeshStandardMaterial({
            color: getNameplateColor(selectedMaterial),
            metalness: 1,
            roughness: 0.1,
        });

        const lastLetter = text[text.length - 1]
        const firstLetter = text[0]

        const firstLetterTextGeometry = new TextGeometry(firstLetter, {
            font: fontPath,
            size: fontSize,
            depth: 1,
            curveSegments: 1,
        });


        const firstLetterMesh = new THREE.Mesh(firstLetterTextGeometry, textMaterial)
        const firstLetterBox = new THREE.Box3().setFromObject(firstLetterMesh);
        const firstLetterSize = firstLetterBox.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
        const firstLetterWidth = firstLetterSize.x;
        const firstLetterHeight = firstLetterSize.y;

        const lastLetterTextGeometry = new TextGeometry(lastLetter, {
            font: fontPath,
            size: fontSize,
            depth: 1,
            curveSegments: 1,
        });

        const lastLetterMesh = new THREE.Mesh(lastLetterTextGeometry, textMaterial)
        const lastLetterBox = new THREE.Box3().setFromObject(lastLetterMesh);
        const lastLetterSize = lastLetterBox.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth

        const controls = new OrbitControls(camera, renderer.domElement);
        controls.update();

        function animate() {

            requestAnimationFrame(animate);

            // required if controls.enableDamping or controls.autoRotate are set to true
            controls.update();

            renderer.render(scene, camera);

        }

        animate()
        // renderer.render(scene, camera);

    }

    function getNameplateColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return 0xfecc86
        } else if (color == NameplateMaterialType.SILVER) {
            return 0xe3e3e3
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return 0xf2cbba
        }
        return 0xeeede7
    }


    const createTextGroup = async (fontModel: Font) => {
        const innerRadius = 2;
        const outerRadius = 4;
        const ringScale = 0.15

        const textGeometry = new TextGeometry(text, {
            font: fontModel,
            size: fontSize,
            depth: (1.1 * 3) / 4,
            curveSegments: 5, // Smoothness
        });

        textGeometry.computeBoundingBox();
        textGeometry.center();

        const textMaterial = new THREE.MeshStandardMaterial({
            color: getNameplateColor(selectedMaterial),
            metalness: 1,
            roughness: 0.1,
        });

        const textMesh = new THREE.Mesh(textGeometry, textMaterial)

        textMesh.position.set(nameplatePosition.x, nameplatePosition.y, 0.25)
        // textMesh.rotation.y = Math.PI

        const box = new THREE.Box3().setFromObject(textMesh);
        const size = box.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
        const width = size.x - 1;
        const height = size.y;

        const ringShape = new THREE.Shape();
        ringShape.absarc(0, 0, outerRadius, 0, Math.PI * 2, false);
        const holePath = new THREE.Path();
        holePath.absarc(0, 0, innerRadius, 0, Math.PI * 2, true);
        ringShape.holes.push(holePath);

        const extrudeSettings = {
            depth: 5.1, // Thickness of the ring
            curveSegments: 12
        };

        const lastLetter = text[text.length - 1]
        const firstLetter = text[0]

        const firstLetterTextGeometry = new TextGeometry(firstLetter, {
            font: fontModel,
            size: fontSize,
            depth: 1,
            curveSegments: 1,
        });

        const firstLetterMesh = new THREE.Mesh(firstLetterTextGeometry, textMaterial)
        const firstLetterBox = new THREE.Box3().setFromObject(firstLetterMesh);
        const firstLetterSize = firstLetterBox.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
        const firstLetterWidth = firstLetterSize.x;
        const firstLetterHeight = firstLetterSize.y;

        const lastLetterTextGeometry = new TextGeometry(lastLetter, {
            font: fontModel,
            size: fontSize,
            depth: 1,
            curveSegments: 1,
        });

        const lastLetterMesh = new THREE.Mesh(lastLetterTextGeometry, textMaterial)
        const lastLetterBox = new THREE.Box3().setFromObject(lastLetterMesh);
        const lastLetterSize = lastLetterBox.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
        const lastLetterWidth = lastLetterSize.x;
        const lastLetterHeight = lastLetterSize.y;

        const fetchResponse = await fetch('/fonts/fonts_centers.json')
        const fetchJson = await fetchResponse.json()
        const selectedFont = fetchJson[font]
        const ringGeometry = new THREE.ExtrudeGeometry(ringShape, extrudeSettings);

        let newSize = fontSize
        if (newSize < 4.75) {
            const diff = 4.75 - newSize
            newSize = 4.75 + diff
        } else if (newSize > 4.75) {
            const diff = newSize - 4.75
            newSize = 4.75 - diff
        }

        const kkkk = (newSize) / 4.75
        const pixelLetterKoef = 24.61 * (newSize / 4.75)
        const fontsKoef = 1 / (pixelLetterKoef)
        const lastCenterX = 250//selectedFont[lastLetter]["imageCenter"]["centerX"]
        const lastCenterY = 250//fontData[lastLetter]["imageCenter"]["centerY"]

        const xRightRing = (selectedFont[lastLetter]["green"]["centerX"] - lastCenterX) * (fontsKoef)
        const yRightRing = (lastCenterY - selectedFont[lastLetter]["blue"]["centerY"]) * (fontsKoef)
        const xLeftRing = (selectedFont[firstLetter]["red"]["centerX"] - selectedFont[firstLetter]["imageCenter"]["centerX"]) * (fontsKoef)
        const yLeftRing = (selectedFont[firstLetter]["imageCenter"]["centerY"] - selectedFont[firstLetter]["red"]["centerY"]) * (fontsKoef)

        
        const leftTextRingPosition = {
            x: -width / 2 + nameplatePosition.x + firstLetterWidth/2 + xLeftRing - 0.5,
            y: nameplatePosition.y + yLeftRing  - (height - firstLetterHeight)/2
        }
        
        const rightTextRingPosition = {
            x: width / 2 + nameplatePosition.x - lastLetterWidth/2 + xRightRing,
            y: nameplatePosition.y + yRightRing// - (height - lastLetterHeight)/2
        }


        const leftRingMesh = new THREE.Mesh(ringGeometry, textMaterial)
        leftRingMesh.scale.set(ringScale, ringScale, ringScale)

        leftRingMesh.position.set(leftTextRingPosition.x, leftTextRingPosition.y, -0.135)
        leftRingMesh.material = textMaterial

        console.log("Width = ", width)
        console.log("Left nameplate position = ", width / 2 + nameplatePosition.x)


        const rightRingMesh = new THREE.Mesh(ringGeometry, textMaterial)
        rightRingMesh.position.set(rightTextRingPosition.x, rightTextRingPosition.y, -0.13)
        rightRingMesh.scale.set(ringScale, ringScale, ringScale)
        rightRingMesh.material = textMaterial

        return {
            textMesh,
            leftRingMesh,
            rightRingMesh,
            leftTextRingPosition,
            rightTextRingPosition
        }
    }

    function getScenePositionByColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return new Position(-16, 6, -3)
        } else if (color == NameplateMaterialType.SILVER) {
            return new Position(-1, 2, -4)
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return new Position(-19, 4, -9)
        }
        // else if (color == NameplateMaterialType.ROSE_GOLD) {
        //   return new Position(-19, 4, -9)
        // }
        return new Position(0, 0, 0)
    }

    function getLightColorByColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return 0xffffff
        } else if (color == NameplateMaterialType.SILVER) {
            return 0xdbdbdb
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return 0xffffff
        }
        // else if (color == NameplateMaterialType.ROSE_GOLD) {
        //   return 0xfcfcfc
        // }
        return 0xdbdbdb
    }



    class Position {
        x = 0
        y = 0
        z = 0

        constructor(x: number, y: number, z: number) {
            this.x = x;
            this.y = y;
            this.z = z;
        }
    }

    function getLightPositionByColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return new Position(-20, -18, 252)
        } else if (color == NameplateMaterialType.SILVER) {
            return new Position(-20, -24, 252)
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return new Position(-2, -2, 113)
        }
        // else if (color == NameplateMaterialType.ROSE_GOLD) {
        //   return new Position(15, -5, 113)
        // }
        return new Position(0, 0, 0)
    }


    return (
        // <ScrollView maximumZoomScale={5} zoomScale={2}>
        <div style={{display: is3dViewEnabled ? 'block' : 'none'}}>
            <canvas style={{ position: 'absolute', left: 200, top: -100 }} ref={canvasRef} />
        </div>

        // </ScrollView>
    );
}