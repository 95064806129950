import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import hdrTexture from '@assets/3dcomponents/kloppenheim_02_puresky_2k.png'
import torus from '@assets/3dcomponents/torupng.png'
import studio_small_09_1k from '@assets/3dcomponents/studio_small_09_1k.png'
import cyclorama_hard_light_1k from '@assets/3dcomponents/cyclorama_hard_light_1k.png'
import anderlechtFontJSON from '../../assets/fonts/Anderlecht_JSON.json'
import connectionsDataJson from './fonts_centers.json';

import leftGoldChainTextureAssetUri from '@assets/chain_gold_L.png'
import rightGoldChainTextureAssetUri from '@assets/chain_gold_R.png'
import leftSilverChainTextureAssetUri from '@assets/chain_silver_L.png'
import rightSilverChainTextureAssetUri from '@assets/chain_silver_R.png'
import leftWhiteGoldChainTextureAssetUri from '@assets/chain_wgold_L.png'
import rightWhiteGoldChainTextureAssetUri from '@assets/chain_wgold_R.png'

import {
    AppMode,
    NameplateMaterialType,
    RulerUnits,
    useCommonState,
    useNameplateSettings,
    useResizeHandler,
} from '../../hooks';
import { backgroundConfigByName } from './backgrounds';
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { mmToInch } from '@/utils/math';
let timeoutId: any;



const leftChainSizeOriginal = { w: 0.918, h: 1.271 }
const rightChainSizeOriginal = { w: 1.124, h: 1.225 }
const nameplatePosition = { x: 0, y: -10 }
const neckLeftPos = { x: -30, y: 46 }
const neckRightPos = { x: 70, y: 55 }


export const ThreeScene = () => {
    const sceneWidth = 2100 + (window.innerWidth - 1625)
    const { rulerIsEnabled, rulerMode, zoom, appMode } = useCommonState();
    const [labelPosition, setLabelPosition] = useState({ x: 0, y: 0 });
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
    const sceneRef = useRef<THREE.Scene | null>(null);
    const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
    const meshSTLRef = useRef<THREE.Mesh | null>(null);
    // const rulerHorizontalRef = useRef<THREE.Line | null>(null);
    // const rulerVerticalRef = useRef<THREE.Line | null>(null);
    const planeRef = useRef<THREE.Mesh | null>(null);
    const leftRingMeshSTLRef = useRef<THREE.Mesh | null>(null);
    const rightRingMeshSTLRef = useRef<THREE.Mesh | null>(null);
    const {
        fontSize,
        letterSpacing,
        font,
        text,
        textDeformation,
        selectedMaterial,
        textSize,
        texturesLoading,
        chainSize,
        onChangeText,
        onChangeFontSize,
        onChangeArea,
        onChangeWidthInMM,
        onChangeHeightInMM,
        onChangeTexturesLoading,
        onChangeSizeLimitState,
        saveSnapshot,
        saveImages,
        widthInMM,
        heightInMM
    } = useNameplateSettings();

    const silverColor = 0xC0C0C0
    const goldColor = 0xFFD700

    const getSnapshot = () => {
        // const camera = cameraRef.current!
        // const renderer = rendererRef.current!
        // const scene = sceneRef.current!
        // camera.position.z = 10
        // renderer.render(scene, camera);
        const dataURL = canvasRef.current!.toDataURL()
        console.log("canvas = ", dataURL)
        saveImages(dataURL, dataURL, dataURL, dataURL)
    }


    useEffect(() => {
        const debounce = (func: any, delay: any) => {
            return function (...args: any) {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => func.apply(args), delay);
            };
        };

        async function fetchAndDisplayImage() {
            console.log("length = ", text.length <= 0)
            if (text.length <= 1){
                return
            }
            // Initialize scene, camera, renderer ONLY ONCE
            if (!sceneRef.current) {
                const scene = new THREE.Scene();
                const camera = new THREE.PerspectiveCamera(75, sceneWidth / window.innerHeight, 0.1, 1000);
                const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current!, alpha: true, antialias: true, preserveDrawingBuffer: true });
                renderer.setClearColor(0xffffff, 0);
                renderer.setSize(sceneWidth, window.innerHeight);
                renderer.setPixelRatio(window.devicePixelRatio); // Match device pixel ratio
                renderer.shadowMap.enabled = true;
                camera.position.x = 0
                camera.position.y = 0
                camera.position.z = 60

                const directionalLight = new THREE.DirectionalLight(getLightColorByColor(selectedMaterial), 1); // Stronger and more direct light
                const lightPosition = getLightPositionByColor(selectedMaterial)
                directionalLight.position.set(lightPosition.x, lightPosition.y, lightPosition.z); // Slightly off-center
                directionalLight.castShadow = true;
                scene.add(directionalLight);

                // directionalLight.shadow.mapSize.width = sceneWidth;
                // directionalLight.shadow.mapSize.height = window.innerHeight;
                // directionalLight.shadow.camera.near = 6;
                // directionalLight.shadow.camera.far = 6000;

                const rgbeLoader = new RGBELoader();
                const silverHdriTexture = await rgbeLoader.loadAsync(cyclorama_hard_light_1k)
                const goldHdriTexture = await rgbeLoader.loadAsync(studio_small_09_1k)
                let hdriTexture = goldHdriTexture
                if (selectedMaterial == NameplateMaterialType.SILVER) {
                    hdriTexture = silverHdriTexture
                }

                hdriTexture.mapping = THREE.EquirectangularReflectionMapping;
                hdriTexture.flipY = true;          // Set during creation
                hdriTexture.premultiplyAlpha = false; // Set during creation
                scene.environment = hdriTexture;


                const envPosition = getScenePositionByColor(selectedMaterial)
                scene.environmentRotation.x = envPosition.x
                scene.environmentRotation.y = envPosition.y
                scene.environmentRotation.z = envPosition.z

                const fontLoader = new FontLoader()
                const defaultFontPath = await fontLoader.loadAsync('fonts/helvetiker_regular.typeface.json');
                const jockyStarlineFontPath = await fontLoader.loadAsync('/fonts/JockyStarline_JSON.json');
                const anderlechtFontPath = await fontLoader.loadAsync('/fonts/Anderlecht_JSON.json');
                const lobsterFontPath = await fontLoader.loadAsync('/fonts/Lobster_JSON.json');
                // const anderlechtFontPath = await fontLoader.loadAsync('./Anderlecht_JSON.json')            
                // const jockyStarlineFontPath = await fontLoader.loadAsync('./Anderlecht_JSON.json')
                // const lobsterFontPath = await fontLoader.loadAsync('./Anderlecht_JSON.json')

                let fontPath = anderlechtFontPath;
                if (font === "Anderlecht") {
                    fontPath = anderlechtFontPath;
                } else if (font === "JockyStarline") {
                    fontPath = jockyStarlineFontPath;
                } else if (font === "Lobster") {
                    fontPath = lobsterFontPath;
                }


                console.log("Creating text group for text = ", text)
                const textMeshGroup = await createTextGroup(scene, fontPath)
                const textMesh = textMeshGroup.textMesh
                const leftRingMesh = textMeshGroup.leftRingMesh
                const rightRingMesh = textMeshGroup.rightRingMesh
                const leftCirclePosition = textMeshGroup.leftTextRingPosition;
                const rightCirclePosition = textMeshGroup.rightTextRingPosition;

                const group = new THREE.Group();
                group.add(textMesh);
                group.add(leftRingMesh);
                group.add(rightRingMesh);
                // group.rotation.y = Math.PI
                scene.add(group)

                const box = new THREE.Box3().setFromObject(textMesh);
                const size = box.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
                const width = size.x;
                const height = size.y;


                const textMaterial = new THREE.MeshStandardMaterial({
                    color: getNameplateColor(selectedMaterial),
                    metalness: 1,
                    roughness: 0.1,
                });

                const lastLetter = text[text.length - 1]
                const firstLetter = text[0]

                const firstLetterTextGeometry = new TextGeometry(firstLetter, {
                    font: fontPath,
                    size: fontSize,
                    depth: 1,
                    curveSegments: 1,
                });


                const firstLetterMesh = new THREE.Mesh(firstLetterTextGeometry, textMaterial)
                const firstLetterBox = new THREE.Box3().setFromObject(firstLetterMesh);
                const firstLetterSize = firstLetterBox.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
                const firstLetterWidth = firstLetterSize.x;
                const firstLetterHeight = firstLetterSize.y;

                const lastLetterTextGeometry = new TextGeometry(lastLetter, {
                    font: fontPath,
                    size: fontSize,
                    depth: 1,
                    curveSegments: 1,
                });

                const lastLetterMesh = new THREE.Mesh(lastLetterTextGeometry, textMaterial)
                const lastLetterBox = new THREE.Box3().setFromObject(lastLetterMesh);
                const lastLetterSize = lastLetterBox.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
                const lastLetterWidth = lastLetterSize.x;
                const lastLetterHeight = lastLetterSize.y;

                const textureLoader = new THREE.TextureLoader();
                const leftGoldChainTexture = await textureLoader.loadAsync(leftGoldChainTextureAssetUri!)
                const rightGoldChainTexture = await textureLoader.loadAsync(rightGoldChainTextureAssetUri!)
                const leftSilverChainTexture = await textureLoader.loadAsync(leftSilverChainTextureAssetUri!)
                const rightSilverChainTexture = await textureLoader.loadAsync(rightSilverChainTextureAssetUri!)
                const leftWhiteGoldChainTexture = await textureLoader.loadAsync(leftWhiteGoldChainTextureAssetUri!)
                const rightWhiteGoldChainTexture = await textureLoader.loadAsync(rightWhiteGoldChainTextureAssetUri!)

                let leftChainTexture = leftGoldChainTexture
                let rightChainTexture = rightGoldChainTexture

                if (selectedMaterial == NameplateMaterialType.SILVER) {
                    leftChainTexture = leftSilverChainTexture
                    rightChainTexture = rightSilverChainTexture
                } else if (selectedMaterial == NameplateMaterialType.ROSE_GOLD) {
                    leftChainTexture = leftWhiteGoldChainTexture
                    rightChainTexture = rightWhiteGoldChainTexture
                }

                const leftChainMesh = createLeftChain(leftCirclePosition, leftChainTexture)
                scene.add(leftChainMesh);

                const rightChainMesh = createRightChain(rightCirclePosition, rightChainTexture)
                scene.add(rightChainMesh);


                const ringChainScale = 0.28

                const leftChainRing = createChainRing(
                    ringChainScale,
                    {
                        x: leftCirclePosition.x - ringChainScale * 2,
                        y: leftCirclePosition.y + ringChainScale,
                        z: 0.2
                    },
                    {
                        x: 1.1,
                        y: 2.2,
                        z: 1.5
                    }
                )
                scene.add(leftChainRing);

                const rightChainRing = createChainRing(
                    ringChainScale,
                    {
                        x: rightCirclePosition.x + ringChainScale,
                        y: rightCirclePosition.y + ringChainScale,
                        z: -0.1
                    },
                    {
                        x: 1,
                        y: 1.1,
                        z: 0
                    }
                )
                scene.add(rightChainRing);


                if (rulerIsEnabled) {
                    // Ruler properties
                    const rulerWidth = width; // Physical width of the ruler in world units
                    const rulerHeight = height; // Physical height of the ruler in world units

                    const isCM = rulerMode === RulerUnits.CM;
                    const widthReal = isCM ? widthInMM : mmToInch(widthInMM) * 10;
                    const heightReal = isCM ? heightInMM : mmToInch(heightInMM) * 10;

                    const tickSpacing = 1; // Spacing between ticks in mm
                    const smallTickSize = 0.5; // Length of small ticks in world units
                    const largeTickSize = 1.5; // Length of large ticks in world units

                    // Conversion factors
                    const widthScale = rulerWidth / widthReal; // Conversion factor for width (world units per mm)
                    const heightScale = rulerHeight / heightReal; // Conversion factor for height (world units per mm)

                    // Group to hold all ruler elements
                    const rulerGroup = new THREE.Group();
                    scene.add(rulerGroup);
                    rulerGroup.position.set(nameplatePosition.x - width / 2, nameplatePosition.y - height / 2, 0);

                    // Create the X-axis ruler
                    const xRuler = new THREE.Group();
                    const xLineGeometry = new LineGeometry();
                    xLineGeometry.setPositions([0, 0, 0, rulerWidth, 0, 0]);

                    const lineMaterial = new LineMaterial({
                        color: 0x000000,
                        linewidth: 0.001, // Line width in world units
                    });

                    const xLine = new Line2(xLineGeometry, lineMaterial);
                    xLine.computeLineDistances();
                    xRuler.add(xLine);

                    // Add ticks to the X-axis
                    for (let i = 0; i <= widthReal / tickSpacing; i++) {
                        let tickSize = smallTickSize; // Default to small ticks

                        // Check if it's a larger tick (every 10th tick)
                        if (i % 5 === 0) {
                            tickSize = largeTickSize / 2 + 0.3; // Use larger tick size
                            if (i % 10 === 0) {
                                tickSize = largeTickSize;
                                const valueText = isCM ? i * tickSpacing : i * tickSpacing / 10
                                console.log(valueText)
                                const textGeometry = new TextGeometry(`${valueText}`, {
                                    font: defaultFontPath,
                                    size: 2, // Text size in world units
                                    height: 0.5, // Text height in world units
                                });
                                const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
                                const textMesh = new THREE.Mesh(textGeometry, textMaterial);

                                // Scale tick position to match world units
                                textMesh.position.set(i * tickSpacing * widthScale - 2, -largeTickSize - 2, 0); // Position below the large ticks
                                xRuler.add(textMesh);
                            }
                        }

                        // Create tick points for the X-axis
                        const tickPoints = [
                            i * tickSpacing * widthScale, -tickSize, 0, // Start point of the tick
                            i * tickSpacing * widthScale, 0, 0   // End point of the tick
                        ];

                        const tickGeometry = new LineGeometry();
                        tickGeometry.setPositions(tickPoints);

                        const tickMaterial = new LineMaterial({
                            color: 0x000000,
                            linewidth: 0.0005, // Line width in world units
                        });
                        const tick = new Line2(tickGeometry, tickMaterial);
                        tick.computeLineDistances();
                        xRuler.add(tick);
                    }

                    xRuler.position.set(0, 0, 0);
                    rulerGroup.add(xRuler);

                    // Create the Y-axis ruler
                    const yRuler = new THREE.Group();
                    const yLineGeometry = new LineGeometry();
                    yLineGeometry.setPositions([0, 0, 0, 0, rulerHeight, 0]);

                    const ylineMaterial = new LineMaterial({
                        color: 0x000000,
                        linewidth: 0.0005, // Line width in world units
                    });

                    const yLine = new Line2(yLineGeometry, ylineMaterial);
                    yLine.computeLineDistances();
                    yRuler.add(yLine);

                    // Add ticks to the Y-axis
                    for (let i = 0; i <= heightReal / tickSpacing; i++) {
                        let tickSize = smallTickSize; // Default to small ticks

                        // Check if it's a larger tick (every 10th tick)
                        if (i % 5 === 0 && i !== 0) {
                            tickSize = largeTickSize / 2 + 0.3; // Use larger tick size
                            if (i % 10 === 0) {
                                tickSize = largeTickSize;
                                const valueText = isCM ? i * tickSpacing : i * tickSpacing / 10
                                const textGeometry = new TextGeometry(`${valueText}`, {
                                    font: defaultFontPath,
                                    size: 2, // Text size in world units
                                    height: 0.5, // Text height in world units
                                });
                                const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
                                const textMesh = new THREE.Mesh(textGeometry, textMaterial);

                                // Scale tick position to match world units
                                textMesh.position.set(-largeTickSize - 3.5, i * tickSpacing * heightScale - 0.5, 0); // Position to the left of the large ticks
                                yRuler.add(textMesh);
                            }
                        }

                        // Create tick points for the Y-axis
                        const tickPoints = [
                            -tickSize, i * tickSpacing * heightScale, 0, // Start point of the tick
                            0, i * tickSpacing * heightScale, 0   // End point of the tick
                        ];

                        const tickGeometry = new LineGeometry();
                        tickGeometry.setPositions(tickPoints);

                        const yTickMaterial = new LineMaterial({
                            color: 0x000000,
                            linewidth: 0.001, // Line width in world units
                        });
                        const tick = new Line2(tickGeometry, yTickMaterial);
                        tick.computeLineDistances();
                        yRuler.add(tick);
                    }

                    yRuler.position.set(0, 0, 0);
                    rulerGroup.add(yRuler);

                    const fromVertPoint = new THREE.Vector3(nameplatePosition.x + width / 2, nameplatePosition.y - height / 2, 0);  // Start point
                    const toVertPoint = new THREE.Vector3(nameplatePosition.x + width / 2, nameplatePosition.y + height / 2, 0);   // End point
                    const vertLine = createDashedLine(fromVertPoint, toVertPoint)

                    const fromHorizPoint = new THREE.Vector3(nameplatePosition.x - width / 2, nameplatePosition.y + height / 2, 0);  // Start point
                    const toHorizPoint = new THREE.Vector3(nameplatePosition.x + width / 2, nameplatePosition.y + height / 2, 0);   // End point
                    const horizLine = createDashedLine(fromHorizPoint, toHorizPoint)

                    scene.add(vertLine)
                    scene.add(horizLine)
                }

                renderer.render(scene, camera);
                // cameraRef.current = camera
                // rendererRef.current = renderer
                getSnapshot()

            } else {
                console.log("UPDATE SCENE ELEMENTS")
                // Update scene elements when state changes
                updateSceneElements();
            }
        }

        // Create a debounced function to delay the request
        const debouncedFetchImageData = debounce(fetchAndDisplayImage, 300); // Delay of 0.3 seconds

        // Call the debounced function to fetch image data
        debouncedFetchImageData();

        // Clean up only if the component unmounts
        return () => {
            // Clean up Three.js objects when the component unmounts
            if (sceneRef.current) {
                sceneRef.current.traverse((object) => {
                    if (object instanceof THREE.Mesh) {
                        object.geometry.dispose();
                        object.material.dispose();
                    }
                });
            }
            clearTimeout(timeoutId);
            rendererRef.current?.dispose(); // Dispose of the renderer
        };
    }, [text, selectedMaterial, font, fontSize, zoom, chainSize, rulerIsEnabled, rulerMode, widthInMM, heightInMM]); // Dependencies for re-render

    function createDashedLine(fromPoint: THREE.Vector3, toPoint: THREE.Vector3) {

        // Create geometry for the dashed line
        const points = [];
        points.push(fromPoint)
        points.push(toPoint)
        const geometry = new THREE.BufferGeometry().setFromPoints(points);

        // Create dashed line material
        const material = new THREE.LineDashedMaterial({
            color: 0x000000, // Line color
            dashSize: 1, // Length of dashes
            gapSize: 0.5, // Length of gaps between dashes
        });

        // Create the line and set its material
        const line = new THREE.Line(geometry, material);

        // Compute distances for dashed line rendering
        line.computeLineDistances();


        return line
    }

    function getRingColorByColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return 0xffd9ad
        } else if (color == NameplateMaterialType.SILVER) {
            return 0xebebeb
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return 0xfbd6c6
        }
        // else if (color == NameplateMaterialType.ROSE_GOLD) {
        //   return 0xeeeee7
        // }
    }

    const createChainRing = (scale: any, position: any, rotation: any) => {
        const radius = 2;        // Radius of the torus ring
        const tubeRadius = 0.7;   // Radius of the tube
        const radialSegments = 32;  // Number of segments around the radius of the torus
        const tubularSegments = 64; // Number of segments around the tube
        const ringChainTexture = new THREE.TorusGeometry(radius, tubeRadius, radialSegments, tubularSegments);
        const ringChainMaterial = new THREE.MeshStandardMaterial({
            color: getRingColorByColor(selectedMaterial),  // Lighter gold color
            metalness: 1,
            roughness: 0.1, // Reduced roughness for more specular reflections
        });
        const chainRing = new THREE.Mesh(ringChainTexture, ringChainMaterial)
        chainRing.rotation.set(rotation.x, rotation.y, rotation.z)
        chainRing.position.set(position.x + scale, position.y + scale, position.z)
        chainRing.scale.set(scale, scale, scale)
        return chainRing
    }

    const createLeftChain = (leftCirclePosition: any, chainTexture: any) => {
        const originalWidth = leftChainSizeOriginal.w;
        const originalHeight = leftChainSizeOriginal.h;
        const topLeftPos = neckLeftPos;
        const bottomRightPos = leftCirclePosition;
        const distanceHeight = topLeftPos.y - bottomRightPos.y
        const distanceWidth = - topLeftPos.x + bottomRightPos.x
        const rectangleMaterial = new THREE.MeshBasicMaterial({ map: chainTexture }) //new THREE.MeshBasicMaterial({color: 'red'})//new THREE.MeshBasicMaterial({ map: nameplate.color == NameplateColor.SILVER ? assets?.leftSilverChainTexture : nameplate.color == NameplateColor.GOLD ? assets?.leftGoldChainTexture : nameplate.color == NameplateColor.WHITE ? assets?.leftWhiteGoldChainTexture : assets?.leftSilverChainTexture, transparent: true })
        rectangleMaterial.transparent = true
        const rectangleGeometry = new THREE.PlaneGeometry(originalWidth, originalHeight);
        const chainMesh = new THREE.Mesh(rectangleGeometry, rectangleMaterial);
        const scaleX = distanceWidth / originalWidth
        const scaleY = distanceHeight / originalHeight
        chainMesh.scale.set(scaleX, scaleY, 2)
        chainMesh.position.set(neckLeftPos.x + (scaleX / 2) * originalWidth - originalWidth / 2, neckLeftPos.y - (scaleY / 2) * originalHeight + originalHeight / 2, 0)
        return chainMesh
    }

    const createRightChain = (rightCirclePosition: any, chainTexture: any) => {
        const rightChainMaterial = new THREE.MeshBasicMaterial({ map: chainTexture }) //new THREE.MeshBasicMaterial({color: 'red'})//new THREE.MeshBasicMaterial({ map: nameplate.color == NameplateColor.SILVER ? assets?.rightSilverChainTexture : nameplate.color == NameplateColor.GOLD ? assets?.rightGoldChainTexture : nameplate.color == NameplateColor.WHITE ? assets?.rightWhiteGoldChainTexture : assets?.rightSilverChainTexture, transparent: true }) // new MeshBasicMaterial({color: 'red'})//
        rightChainMaterial.transparent = true
        const rightChainGeometry = new THREE.PlaneGeometry(rightChainSizeOriginal.w, rightChainSizeOriginal.h);
        const rightChainMesh = new THREE.Mesh(rightChainGeometry, rightChainMaterial);
        const rightChainScale = { x: (neckRightPos.x - rightCirclePosition.x) / rightChainSizeOriginal.w, y: (neckRightPos.y - rightCirclePosition.y) / rightChainSizeOriginal.h, z: 1 }
        const newRightChainSize = { w: rightChainSizeOriginal.w * (rightChainScale.x), h: rightChainSizeOriginal.h * (rightChainScale.y) }
        rightChainMesh.position.set(rightCirclePosition.x + newRightChainSize.w / 2 + 0.2, neckRightPos.y - newRightChainSize.h / 2, 0)
        rightChainMesh.scale.set(rightChainScale.x, rightChainScale.y, rightChainScale.z)
        return rightChainMesh
    }

    function getNameplateColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return 0xfecc86
        } else if (color == NameplateMaterialType.SILVER) {
            return 0xe3e3e3
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return 0xf2cbba
        }
        return 0xeeede7
    }


    const createTextGroup = async (scene:any, fontModel: Font) => {
        nameplatePosition.y = chainSize == 16 ? -5 : -8
        const innerRadius = 2;
        const outerRadius = 4;
        const ringScale = 0.15

        const textGeometry = new TextGeometry(text, {
            font: fontModel,
            size: fontSize,
            depth: (1.1 * 3) / 4,
            curveSegments: 12, // Smoothness
        });

        textGeometry.computeBoundingBox();
        textGeometry.center();

        const textMaterial = new THREE.MeshStandardMaterial({
            color: getNameplateColor(selectedMaterial),
            metalness: 1,
            roughness: 0.1,
        });

        const textMesh = new THREE.Mesh(textGeometry, textMaterial)

        textMesh.position.set(nameplatePosition.x, nameplatePosition.y, 0.25)
        // textMesh.rotation.y = Math.PI

        const box = new THREE.Box3().setFromObject(textMesh);
        const size = box.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
        const width = size.x - 1;
        const height = size.y;

        const ringShape = new THREE.Shape();
        ringShape.absarc(0, 0, outerRadius, 0, Math.PI * 2, false);
        const holePath = new THREE.Path();
        holePath.absarc(0, 0, innerRadius, 0, Math.PI * 2, true);
        ringShape.holes.push(holePath);

        const extrudeSettings = {
            depth: 3, // Thickness of the ring
            curveSegments: 12
        };

        const lastLetter = text[text.length - 1]
        const firstLetter = text[0]

        const firstLetterTextGeometry = new TextGeometry(firstLetter, {
            font: fontModel,
            size: fontSize,
            depth: 1,
            curveSegments: 1,
        });

        const firstLetterMesh = new THREE.Mesh(firstLetterTextGeometry, textMaterial)
        const firstLetterBox = new THREE.Box3().setFromObject(firstLetterMesh);
        const firstLetterSize = firstLetterBox.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
        const firstLetterWidth = firstLetterSize.x;
        const firstLetterHeight = firstLetterSize.y;

        const lastLetterTextGeometry = new TextGeometry(lastLetter, {
            font: fontModel,
            size: fontSize,
            depth: 1,
            curveSegments: 1,
        });

        const lastLetterMesh = new THREE.Mesh(lastLetterTextGeometry, textMaterial)
        const lastLetterBox = new THREE.Box3().setFromObject(lastLetterMesh);
        const lastLetterSize = lastLetterBox.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
        const lastLetterWidth = lastLetterSize.x;
        const lastLetterHeight = lastLetterSize.y;

        console.log("lastLetter = ", lastLetter)
        console.log("SELECTED FONT = ", font)
        const fetchResponse = await fetch('/fonts/fonts_centers.json')
        const fetchJson = await fetchResponse.json()
        const selectedFont = fetchJson[font]
        console.log(selectedFont)
        const ringGeometry = new THREE.ExtrudeGeometry(ringShape, extrudeSettings);

        let newSize = fontSize
        if (newSize < 4.75) {
            const diff = 4.75 - newSize
            newSize = 4.75 + diff
        } else if (newSize > 4.75) {
            const diff = newSize - 4.75
            newSize = 4.75 - diff
        }

        const kkkk = (newSize) / 4.75
        console.log("kkkk = ", kkkk)
        const pixelLetterKoef = 24.61 * (newSize / 4.75)
        console.log("pixelLetterKoef = ", pixelLetterKoef)
        const fontsKoef = 1 / (pixelLetterKoef)
        const lastCenterX = 250//selectedFont[lastLetter]["imageCenter"]["centerX"]
        const lastCenterY = 250//fontData[lastLetter]["imageCenter"]["centerY"]

        const xRightRing = (selectedFont[lastLetter]["green"]["centerX"] - lastCenterX) * (fontsKoef)
        const yRightRing = (lastCenterY - selectedFont[lastLetter]["blue"]["centerY"]) * (fontsKoef)
        console.log("yRightRing = ", yRightRing)
        console.log("centerY = ", selectedFont[lastLetter]["blue"]["centerY"])
        // console.log("LAST LETTR = ", lastLetter)
        console.log("SF = ", selectedFont[firstLetter])
        const xLeftRing = (selectedFont[firstLetter]["red"]["centerX"] - selectedFont[firstLetter]["imageCenter"]["centerX"]) * (fontsKoef)
        const yLeftRing = (selectedFont[firstLetter]["imageCenter"]["centerY"] - selectedFont[firstLetter]["red"]["centerY"]) * (fontsKoef)

        
        const leftTextRingPosition = {
            x: -width / 2 + nameplatePosition.x + firstLetterWidth/2 + xLeftRing - 0.5,
            y: nameplatePosition.y + yLeftRing  - (height - firstLetterHeight)/2
        }
        
        const rightTextRingPosition = {
            x: width / 2 + nameplatePosition.x - lastLetterWidth/2 + xRightRing,
            y: nameplatePosition.y + yRightRing// - (height - lastLetterHeight)/2
        }

        const leftRingMesh = new THREE.Mesh(ringGeometry, textMaterial)
        leftRingMesh.scale.set(ringScale, ringScale, ringScale)

        leftRingMesh.position.set(leftTextRingPosition.x, leftTextRingPosition.y, -0.25)
        leftRingMesh.material = textMaterial

        console.log("Width = ", width)
        console.log("Left nameplate position = ", width / 2 + nameplatePosition.x)


        // const rightSquareGeom = new THREE.BoxGeometry(1,1)
        // const rightSquareMat = new THREE.MeshBasicMaterial({color: '#ff0000'})
        // const rightSquareMesh = new THREE.Mesh(rightSquareGeom, rightSquareMat)
        // rightSquareMesh.position.set(rightTextRingPosition2.x, rightTextRingPosition2.y, 1)
        // scene.add(rightSquareMesh)

        const rightRingMesh = new THREE.Mesh(ringGeometry, textMaterial)
        rightRingMesh.position.set(rightTextRingPosition.x, rightTextRingPosition.y, -0.25)
        rightRingMesh.scale.set(ringScale, ringScale, ringScale)
        rightRingMesh.material = textMaterial

        return {
            textMesh,
            leftRingMesh,
            rightRingMesh,
            leftTextRingPosition,
            rightTextRingPosition
        }
    }

    function getScenePositionByColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return new Position(-16, 6, -3)
        } else if (color == NameplateMaterialType.SILVER) {
            return new Position(-1, 2, -4)
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return new Position(-19, 4, -9)
        }
        // else if (color == NameplateMaterialType.ROSE_GOLD) {
        //   return new Position(-19, 4, -9)
        // }
        return new Position(0, 0, 0)
    }

    function getLightColorByColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return 0xffffff
        } else if (color == NameplateMaterialType.SILVER) {
            return 0xdbdbdb
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return 0xffffff
        }
        // else if (color == NameplateMaterialType.ROSE_GOLD) {
        //   return 0xfcfcfc
        // }
        return 0xdbdbdb
    }



    class Position {
        x = 0
        y = 0
        z = 0

        constructor(x: number, y: number, z: number) {
            this.x = x;
            this.y = y;
            this.z = z;
        }
    }

    function getLightPositionByColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return new Position(-2, -2, 252)
        } else if (color == NameplateMaterialType.SILVER) {
            return new Position(-20, -24, 252)
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return new Position(-2, 10, 252)
        }
        // else if (color == NameplateMaterialType.ROSE_GOLD) {
        //   return new Position(15, -5, 113)
        // }
        return new Position(0, 0, 0)
    }

    function updateSceneElements() {
        if (text.length <= 1) {
            return
        }
        const scene = sceneRef.current;
        const renderer = rendererRef.current;
        const camera = cameraRef.current;
        if (scene && renderer && camera) {

            const dataURL = rendererRef.current!.domElement.toDataURL('image/png');
            // saveImages(dataURL, dataURL, dataURL, dataURL)
            // camera.position.z = 60 * zoom
            // Remove existing mesh, plane, and rings
            // scene.remove(meshSTLRef.current!, planeRef.current!, leftRingMeshSTLRef.current!, rightRingMeshSTLRef.current!);
            scene.remove(meshSTLRef.current!)
            scene.remove(planeRef.current!)
            scene.remove(leftRingMeshSTLRef.current!)
            scene.remove(rightRingMeshSTLRef.current!)
            // scene.remove(rulerHorizontalRef.current!)
            // scene.remove(rulerVerticalRef.current!)


        }
    }

    return (
        <div>
            <canvas style={{ position: 'absolute', left: 0, top: 300 }} ref={canvasRef} />
        </div>
    );
}

