import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

import { Line2 } from 'three/examples/jsm/lines/Line2.js';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial.js';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry.js';



import studio_small_09_1k from '@assets/3dcomponents/studio_small_09_1k.png'
import cyclorama_hard_light_1k from '@assets/3dcomponents/cyclorama_hard_light_1k.png'
import { Font, FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import leftGoldChainTextureAssetUri from '@assets/chain_gold_L.png'
import rightGoldChainTextureAssetUri from '@assets/chain_gold_R.png'
import leftSilverChainTextureAssetUri from '@assets/chain_silver_L.png'
import rightSilverChainTextureAssetUri from '@assets/chain_silver_R.png'
import leftWhiteGoldChainTextureAssetUri from '@assets/chain_wgold_L.png'
import rightWhiteGoldChainTextureAssetUri from '@assets/chain_wgold_R.png'

// import hdrTexture from '@assets/3dcomponents/citrus_orchard_road_1k.png'
import hdrTexture from '@assets/3dcomponents/studio_small_08_1k.png'
// import hdrTexture from '@assets/3dcomponents/hotel_rooftop_balcony_1k.png'
// import { useFrame } from '@react-three/fiber';

import {
    AppMode,
    NameplateMaterialType,
    RulerUnits,
    useCommonState,
    useNameplateSettings,
    useResizeHandler,
} from '../../hooks';
import { mmToInch } from '@/utils/math';
let timeoutId: any;


const leftChainSizeOriginal = { w: 0.918, h: 1.271 }
const rightChainSizeOriginal = { w: 1.124, h: 1.225 }
const nameplatePosition = { x: 5, y: 30 }
const neckLeftPos = { x: -23, y: 70 }
const neckRightPos = { x: 45, y: 83 }


export const MobileThreeScene = () => {

    const { rulerIsEnabled, rulerMode, zoom, appMode } = useCommonState();
    const [labelPosition, setLabelPosition] = useState({ x: 0, y: 0 });
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
    const sceneRef = useRef<THREE.Scene | null>(null);
    const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
    const textRef = useRef<THREE.Mesh | null>(null);
    // const rulerHorizontalRef = useRef<THREE.Line | null>(null);
    // const rulerVerticalRef = useRef<THREE.Line | null>(null);
    const leftChainMeshRef = useRef<THREE.Mesh | null>(null);
    const rightChainMeshRef = useRef<THREE.Mesh | null>(null);
    const leftWordRingMeshRef = useRef<THREE.Mesh | null>(null);
    const rightWordRingMeshRef = useRef<THREE.Mesh | null>(null);
    const fontModelRef = useRef<Font | null>(null);
    const defaultFontModelRef = useRef<Font | null>(null);
    const leftChainTextureRef = useRef<THREE.Texture | null>(null);
    const rightChainTextureRef = useRef<THREE.Texture | null>(null);

    const leftChainRingRef = useRef<THREE.Mesh | null>(null);
    const rightChainRingRef = useRef<THREE.Mesh | null>(null);

    const rulerHorLineRef = useRef<THREE.Line | null>(null);
    const rulerVerLineRef = useRef<THREE.Line | null>(null);
    const rulerGroupRef = useRef<THREE.Group | null>(null);


    const selectedFontRef = useRef(null);

    const {
        fontSize,
        letterSpacing,
        font,
        text,
        textDeformation,
        selectedMaterial,
        textSize,
        texturesLoading,
        chainSize,
        onChangeText,
        onChangeFontSize,
        onChangeArea,
        onChangeWidthInMM,
        onChangeHeightInMM,
        onChangeTexturesLoading,
        onChangeSizeLimitState,
        saveSnapshot,
        widthInMM,
        heightInMM,
        saveImages,
    } = useNameplateSettings();

    const silverColor = 0xc0c0c0
    const goldColor = 0xFFD700



    useEffect(() => {
        const debounce = (func: any, delay: any) => {
            return function (...args: any) {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => func.apply(args), delay);
            };
        };

        async function fetchAndDisplayImage() {

            if (text.length <= 1) {
                return
            }
            const sceneHeight = 844//window.innerHeight
            const sceneWidth = 390//window.innerWidth
            // Initialize scene, camera, renderer ONLY ONCE
            if (!sceneRef.current || sceneRef.current) {
                const scene = new THREE.Scene();
                const camera = new THREE.PerspectiveCamera(75, sceneWidth / sceneHeight, 0.1, 1000);
                const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current!, alpha: true, antialias: true, preserveDrawingBuffer: true });
                renderer.setClearColor(0xffffff, 0);
                renderer.setSize(sceneWidth, sceneHeight);
                renderer.setPixelRatio(window.devicePixelRatio); // Match device pixel ratio
                renderer.shadowMap.enabled = true;
                camera.position.x = 0
                camera.position.y = 0
                camera.position.z = 120

                const directionalLight = new THREE.DirectionalLight(getLightColorByColor(selectedMaterial), 1); // Stronger and more direct light
                const lightPosition = getLightPositionByColor(selectedMaterial)
                directionalLight.position.set(lightPosition.x, lightPosition.y + 40, lightPosition.z); // Slightly off-center
                directionalLight.castShadow = true;
                scene.add(directionalLight);

                const rgbeLoader = new RGBELoader();
                const silverHdriTexture = await rgbeLoader.loadAsync(cyclorama_hard_light_1k)
                const goldHdriTexture = await rgbeLoader.loadAsync(studio_small_09_1k)
                let hdriTexture = goldHdriTexture
                if (selectedMaterial == NameplateMaterialType.SILVER) {
                    hdriTexture = silverHdriTexture
                }

                hdriTexture.mapping = THREE.EquirectangularReflectionMapping;
                hdriTexture.flipY = true;          // Set during creation
                hdriTexture.premultiplyAlpha = false; // Set during creation
                scene.environment = hdriTexture;


                const envPosition = getScenePositionByColor(selectedMaterial)
                scene.environmentRotation.x = envPosition.x
                scene.environmentRotation.y = envPosition.y - 0.3
                scene.environmentRotation.z = envPosition.z

                const fontLoader = new FontLoader()
                const defaultFontPath = await fontLoader.loadAsync('/fonts/helvetiker_regular.typeface.json');
                defaultFontModelRef.current = defaultFontPath
                const jockyStarlineFontPath = await fontLoader.loadAsync('/fonts/JockyStarline_JSON.json');

                const anderlechtFontPath = await fontLoader.loadAsync('/fonts/Anderlecht_JSON.json');

                const lobsterFontPath = await fontLoader.loadAsync('/fonts/Lobster_JSON.json');

                // const anderlechtFontPath = await fontLoader.loadAsync('./Anderlecht_JSON.json')            
                // const jockyStarlineFontPath = await fontLoader.loadAsync('./Anderlecht_JSON.json')
                // const lobsterFontPath = await fontLoader.loadAsync('./Anderlecht_JSON.json')

                let fontPath = anderlechtFontPath;
                if (font === "Anderlecht") {
                    fontPath = anderlechtFontPath;
                } else if (font === "JockyStarline") {
                    fontPath = jockyStarlineFontPath;
                } else if (font === "Lobster") {
                    fontPath = lobsterFontPath;
                }

                fontModelRef.current = fontPath;

                const textMeshGroup = await createTextGroup(fontPath)
                const textMesh = textMeshGroup.textMesh
                const leftRingMesh = textMeshGroup.leftRingMesh
                const rightRingMesh = textMeshGroup.rightRingMesh
                const leftCirclePosition = textMeshGroup.leftTextRingPosition;
                const rightCirclePosition = textMeshGroup.rightTextRingPosition;

                const group = new THREE.Group();
                group.add(textMesh);
                group.add(leftRingMesh);
                group.add(rightRingMesh);
                // group.rotation.y = Math.PI
                group.position.set(0, 0, 0)
                scene.add(group)
                textRef.current = textMesh;
                leftWordRingMeshRef.current = leftRingMesh
                rightWordRingMeshRef.current = rightRingMesh

                const textureLoader = new THREE.TextureLoader();
                const leftGoldChainTexture = await textureLoader.loadAsync(leftGoldChainTextureAssetUri!)
                const rightGoldChainTexture = await textureLoader.loadAsync(rightGoldChainTextureAssetUri!)
                const leftSilverChainTexture = await textureLoader.loadAsync(leftSilverChainTextureAssetUri!)
                const rightSilverChainTexture = await textureLoader.loadAsync(rightSilverChainTextureAssetUri!)
                const leftWhiteGoldChainTexture = await textureLoader.loadAsync(leftWhiteGoldChainTextureAssetUri!)
                const rightWhiteGoldChainTexture = await textureLoader.loadAsync(rightWhiteGoldChainTextureAssetUri!)

                let leftChainTexture = leftGoldChainTexture
                let rightChainTexture = rightGoldChainTexture

                if (selectedMaterial == NameplateMaterialType.SILVER) {
                    leftChainTexture = leftSilverChainTexture
                    rightChainTexture = rightSilverChainTexture
                } else if (selectedMaterial == NameplateMaterialType.ROSE_GOLD) {
                    leftChainTexture = leftWhiteGoldChainTexture
                    rightChainTexture = rightWhiteGoldChainTexture
                }
                leftChainTextureRef.current = leftChainTexture
                rightChainTextureRef.current = rightChainTexture

                const leftChainMesh = createLeftChain(leftCirclePosition, leftChainTexture)
                scene.add(leftChainMesh);
                leftChainMeshRef.current = leftChainMesh

                const rightChainMesh = createRightChain(rightCirclePosition, rightChainTexture)
                scene.add(rightChainMesh);
                rightChainMeshRef.current = rightChainMesh


                const ringChainScale = 0.28

                const leftChainRing = createChainRing(
                    ringChainScale,
                    {
                        x: leftCirclePosition.x - ringChainScale * 2,
                        y: leftCirclePosition.y + ringChainScale,
                        z: 0.2
                    },
                    {
                        x: 1.1,
                        y: 2.2,
                        z: 1.5
                    }
                )
                scene.add(leftChainRing);
                leftChainRingRef.current = leftChainRing

                const rightChainRing = createChainRing(
                    ringChainScale,
                    {
                        x: rightCirclePosition.x + ringChainScale,
                        y: rightCirclePosition.y + ringChainScale,
                        z: -0.1
                    },
                    {
                        x: 1,
                        y: 1.1,
                        z: 0
                    }
                )
                scene.add(rightChainRing);
                rightChainRingRef.current = rightChainRing


                renderer.render(scene, camera);

                cameraRef.current = camera
                rendererRef.current = renderer
                sceneRef.current = scene
                getSnapshot()

            } else {
                // Update scene elements when state changes
                // updateSceneElements();
            }
        }

        // Create a debounced function to delay the request
        const debouncedFetchImageData = debounce(fetchAndDisplayImage, 300); // Delay of 0.3 seconds

        // Call the debounced function to fetch image data
        debouncedFetchImageData();

        // Clean up only if the component unmounts
        return () => {
            // Clean up Three.js objects when the component unmounts
            if (sceneRef.current) {
                sceneRef.current.traverse((object) => {
                    if (object instanceof THREE.Mesh) {
                        object.geometry.dispose();
                        object.material.dispose();
                    }
                });
            }
            clearTimeout(timeoutId);
            rendererRef.current?.dispose(); // Dispose of the renderer
        };
    }, [text, font, zoom, chainSize, selectedMaterial]); // Dependencies for re-render

    useEffect(() => {
        if (text.length <= 1) {
            return
        }
        if (
            defaultFontModelRef.current &&
            fontModelRef.current &&
            textRef.current &&
            rendererRef.current &&
            sceneRef.current &&
            cameraRef.current &&
            leftWordRingMeshRef.current &&
            rightWordRingMeshRef.current &&
            selectedFontRef.current &&
            rightChainMeshRef.current &&
            leftChainMeshRef.current &&
            leftChainTextureRef.current &&
            rightChainTextureRef.current &&
            leftChainRingRef.current &&
            rightChainRingRef.current
        ) {
            if (rulerGroupRef.current && rulerHorLineRef.current && rulerVerLineRef.current) {
                sceneRef.current.remove(rulerGroupRef.current)
                sceneRef.current.remove(rulerHorLineRef.current)
                sceneRef.current.remove(rulerVerLineRef.current)
            }

            const textGeometry = new TextGeometry(text, {
                font: fontModelRef.current!,
                size: fontSize / 1.1,
                depth: (1.1 * 3) / (4 * 1.1),
                curveSegments: 32, // Smoothness
            });

            textGeometry.computeBoundingBox();
            textGeometry.center();

            textRef.current!.geometry = textGeometry;

            const textMaterial = new THREE.MeshStandardMaterial({
                color: getNameplateColor(selectedMaterial),
                metalness: 1,
                roughness: 0.1,
            });
            textRef.current!.material = textMaterial

            let newSize = fontSize
            if (newSize < 4.75) {
                const diff = 4.75 - newSize
                newSize = 4.75 + diff
            } else if (newSize > 4.75) {
                const diff = newSize - 4.75
                newSize = 4.75 - diff
            }

            const pixelLetterKoef = 24.61 * (newSize / 4.75)
            const fontsKoef = 1 / (pixelLetterKoef)
            const lastCenterX = 250//selectedFont[lastLetter]["imageCenter"]["centerX"]
            const lastCenterY = 250//fontData[lastLetter]["imageCenter"]["centerY"]


            const lastLetter = text[text.length - 1]
            const firstLetter = text[0]

            const selectedFont = selectedFontRef.current!

            const box = new THREE.Box3().setFromObject(textRef.current!);
            const size = box.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
            const width = size.x - 1;
            const height = size.y;

            const xRightRing = (selectedFont[lastLetter]["green"]["centerX"] - lastCenterX) * (fontsKoef)
            const yRightRing = (lastCenterY - selectedFont[lastLetter]["blue"]["centerY"]) * (fontsKoef)
            const xLeftRing = (selectedFont[firstLetter]["red"]["centerX"] - selectedFont[firstLetter]["imageCenter"]["centerX"]) * (fontsKoef)
            const yLeftRing = (selectedFont[firstLetter]["imageCenter"]["centerY"] - selectedFont[firstLetter]["red"]["centerY"]) * (fontsKoef)

            const firstLetterTextGeometry = new TextGeometry(firstLetter, {
                font: fontModelRef.current,
                size: fontSize/1.1,
                depth: 1,
                curveSegments: 1,
            });

            const firstLetterMesh = new THREE.Mesh(firstLetterTextGeometry, new THREE.MeshBasicMaterial())
            const firstLetterBox = new THREE.Box3().setFromObject(firstLetterMesh);
            const firstLetterSize = firstLetterBox.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
            const firstLetterWidth = firstLetterSize.x;
            const firstLetterHeight = firstLetterSize.y;

            const lastLetterTextGeometry = new TextGeometry(lastLetter, {
                font: fontModelRef.current,
                size: fontSize/1.1,
                depth: 1,
                curveSegments: 1,
            });

            const lastLetterMesh = new THREE.Mesh(lastLetterTextGeometry, new THREE.MeshBasicMaterial())
            const lastLetterBox = new THREE.Box3().setFromObject(lastLetterMesh);
            const lastLetterSize = lastLetterBox.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
            const lastLetterWidth = lastLetterSize.x;
            const lastLetterHeight = lastLetterSize.y;

            const leftTextRingPosition = {
                x: -width / 2 + nameplatePosition.x + firstLetterWidth / 2 + xLeftRing - 0.45,
                y: nameplatePosition.y + yLeftRing - ((height - firstLetterHeight) / 2)
            }

            const rightTextRingPosition = {
                x: width / 2 + nameplatePosition.x - lastLetterWidth / 2 + xRightRing,
                y: nameplatePosition.y + yRightRing 
            }

            const ringScale = 0.15 * 1/(newSize/4.5)

            leftWordRingMeshRef.current.scale.set(ringScale,ringScale,ringScale)
            leftWordRingMeshRef.current.position.set(leftTextRingPosition.x, leftTextRingPosition.y, -0.26)

            rightWordRingMeshRef.current.scale.set(ringScale,ringScale,ringScale)
            rightWordRingMeshRef.current.position.set(rightTextRingPosition.x, rightTextRingPosition.y, -0.26)

            sceneRef.current.remove(rightChainMeshRef.current)

            const newRightChain = createRightChain(rightTextRingPosition, rightChainTextureRef.current)
            rightChainMeshRef.current = newRightChain
            sceneRef.current.add(newRightChain)

            sceneRef.current.remove(leftChainMeshRef.current)
            const newLeftChain = createLeftChain(leftTextRingPosition, leftChainTextureRef.current)
            leftChainMeshRef.current = newLeftChain
            sceneRef.current.add(newLeftChain)
            const ringChainScale = 0.28

            const leftRingPosition = {
                x: leftTextRingPosition.x - ringChainScale * 2,
                y: leftTextRingPosition.y + ringChainScale,
                z: 0.2
            };

            const rightRingPosition = {
                x: rightTextRingPosition.x + ringChainScale,
                y: rightTextRingPosition.y + ringChainScale,
                z: -0.1
            };

            leftChainRingRef.current.position.set(leftRingPosition.x + ringChainScale, leftRingPosition.y + ringChainScale, leftRingPosition.z)
            rightChainRingRef.current.position.set(rightRingPosition.x + ringChainScale, rightRingPosition.y + ringChainScale, rightRingPosition.z)

            if (rulerIsEnabled) {
                
                // Ruler properties
                const rulerWidth = width; // Physical width of the ruler in world units
                const rulerHeight = height; // Physical height of the ruler in world units

                const isCM = rulerMode === RulerUnits.CM;
                const widthReal = isCM ? widthInMM : mmToInch(widthInMM) * 10;
                const heightReal = isCM ? heightInMM : mmToInch(heightInMM) * 10;

                const tickSpacing = 1; // Spacing between ticks in mm
                const smallTickSize = 0.5; // Length of small ticks in world units
                const largeTickSize = 1.5; // Length of large ticks in world units

                // Conversion factors
                const widthScale = rulerWidth / widthReal; // Conversion factor for width (world units per mm)
                const heightScale = rulerHeight / heightReal; // Conversion factor for height (world units per mm)

                // Group to hold all ruler elements
                const rulerGroup = new THREE.Group();
                sceneRef.current.add(rulerGroup);
                rulerGroupRef.current = rulerGroup
                rulerGroup.position.set(nameplatePosition.x - width / 2, nameplatePosition.y - height / 2, 0);

                // Create the X-axis ruler
                const xRuler = new THREE.Group();
                const xLineGeometry = new LineGeometry();
                xLineGeometry.setPositions([0, 0, 0, rulerWidth, 0, 0]);

                const lineMaterial = new LineMaterial({
                    color: 0x000000,
                    linewidth: 0.001, // Line width in world units
                });

                const xLine = new Line2(xLineGeometry, lineMaterial);
                xLine.computeLineDistances();
                xRuler.add(xLine);

                // Add ticks to the X-axis
                for (let i = 0; i <= widthReal / tickSpacing; i++) {
                    let tickSize = smallTickSize; // Default to small ticks

                    // Check if it's a larger tick (every 10th tick)
                    if (i % 5 === 0) {
                        tickSize = largeTickSize / 2 + 0.3; // Use larger tick size
                        if (i % 10 === 0) {
                            tickSize = largeTickSize;
                            const valueText = isCM ? i * tickSpacing : i * tickSpacing / 10

                            const textGeometry = new TextGeometry(`${valueText}`, {
                                font: defaultFontModelRef.current,
                                size: 2, // Text size in world units
                                height: 0.5, // Text height in world units
                            });
                            const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
                            const textMesh = new THREE.Mesh(textGeometry, textMaterial);

                            // Scale tick position to match world units
                            textMesh.position.set(i * tickSpacing * widthScale - 2, -largeTickSize - 2, 0); // Position below the large ticks
                            xRuler.add(textMesh);
                        }
                    }

                    // Create tick points for the X-axis
                    const tickPoints = [
                        i * tickSpacing * widthScale, -tickSize, 0, // Start point of the tick
                        i * tickSpacing * widthScale, 0, 0   // End point of the tick
                    ];

                    const tickGeometry = new LineGeometry();
                    tickGeometry.setPositions(tickPoints);

                    const tickMaterial = new LineMaterial({
                        color: 0x000000,
                        linewidth: 0.001, // Line width in world units
                    });
                    const tick = new Line2(tickGeometry, tickMaterial);
                    tick.computeLineDistances();
                    xRuler.add(tick);
                }

                xRuler.position.set(0, 0, 0);
                rulerGroup.add(xRuler);

                // Create the Y-axis ruler
                const yRuler = new THREE.Group();
                const yLineGeometry = new LineGeometry();
                yLineGeometry.setPositions([0, 0, 0, 0, rulerHeight, 0]);

                const ylineMaterial = new LineMaterial({
                    color: 0x000000,
                    linewidth: 0.001, // Line width in world units
                });

                const yLine = new Line2(yLineGeometry, ylineMaterial);
                yLine.computeLineDistances();
                yRuler.add(yLine);

                // Add ticks to the Y-axis
                for (let i = 0; i <= heightReal / tickSpacing; i++) {
                    let tickSize = smallTickSize; // Default to small ticks

                    // Check if it's a larger tick (every 10th tick)
                    if (i % 5 === 0 && i !== 0) {
                        tickSize = largeTickSize / 2 + 0.3; // Use larger tick size
                        if (i % 10 === 0) {
                            tickSize = largeTickSize;
                            const valueText = isCM ? i * tickSpacing : i * tickSpacing / 10
                            const textGeometry = new TextGeometry(`${valueText}`, {
                                font: defaultFontModelRef.current,
                                size: 2, // Text size in world units
                                height: 0.5, // Text height in world units
                            });
                            const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
                            const textMesh = new THREE.Mesh(textGeometry, textMaterial);

                            // Scale tick position to match world units
                            textMesh.position.set(-largeTickSize - 3.5, i * tickSpacing * heightScale - 0.5, 0); // Position to the left of the large ticks
                            yRuler.add(textMesh);
                        }
                    }

                    // Create tick points for the Y-axis
                    const tickPoints = [
                        -tickSize, i * tickSpacing * heightScale, 0, // Start point of the tick
                        0, i * tickSpacing * heightScale, 0   // End point of the tick
                    ];

                    const tickGeometry = new LineGeometry();
                    tickGeometry.setPositions(tickPoints);


                    const yTickMaterial = new LineMaterial({
                        color: 0x000000,
                        linewidth: 0.0005, // Line width in world units
                    });
                    const tick = new Line2(tickGeometry, yTickMaterial);
                    tick.computeLineDistances();
                    yRuler.add(tick);
                }

                yRuler.position.set(0, 0, 0);
                rulerGroup.add(yRuler);


                const fromVertPoint = new THREE.Vector3(nameplatePosition.x + width / 2, nameplatePosition.y - height / 2, 0);  // Start point
                const toVertPoint = new THREE.Vector3(nameplatePosition.x + width / 2, nameplatePosition.y + height / 2, 0);   // End point
                const vertLine = createDashedLine(fromVertPoint, toVertPoint)

                const fromHorizPoint = new THREE.Vector3(nameplatePosition.x - width / 2, nameplatePosition.y + height / 2, 0);  // Start point
                const toHorizPoint = new THREE.Vector3(nameplatePosition.x + width / 2, nameplatePosition.y + height / 2, 0);   // End point
                const horizLine = createDashedLine(fromHorizPoint, toHorizPoint)

                sceneRef.current.add(vertLine)
                sceneRef.current.add(horizLine)

                rulerHorLineRef.current = horizLine
                rulerVerLineRef.current = vertLine
            }

            rendererRef.current.render(sceneRef.current, cameraRef.current);

        }
    }, [fontSize, widthInMM, heightInMM, rulerIsEnabled, rulerMode])

    function createDashedLine(fromPoint: THREE.Vector3, toPoint: THREE.Vector3) {

        // Create geometry for the dashed line
        const points = [];
        points.push(fromPoint)
        points.push(toPoint)
        const geometry = new THREE.BufferGeometry().setFromPoints(points);

        // Create dashed line material
        const material = new THREE.LineDashedMaterial({
            color: 0x000000, // Line color
            dashSize: 1, // Length of dashes
            gapSize: 0.5, // Length of gaps between dashes
        });

        // Create the line and set its material
        const line = new THREE.Line(geometry, material);

        // Compute distances for dashed line rendering
        line.computeLineDistances();


        return line
    }


    const getSnapshot = () => {
        // const camera = cameraRef.current!
        // const renderer = rendererRef.current!
        // const scene = sceneRef.current!
        // camera.position.z = 10
        // renderer.render(scene, camera);
        const dataURL = canvasRef.current!.toDataURL()
        saveImages(dataURL, dataURL, dataURL, dataURL)
    }

    function getRingColorByColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return 0xffd9ad
        } else if (color == NameplateMaterialType.SILVER) {
            return 0xebebeb
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return 0xfbd6c6
        }
        // else if (color == NameplateMaterialType.ROSE_GOLD) {
        //   return 0xeeeee7
        // }
    }

    const createChainRing = (scale: any, position: any, rotation: any) => {
        const radius = 2;        // Radius of the torus ring
        const tubeRadius = 0.7;   // Radius of the tube
        const radialSegments = 32;  // Number of segments around the radius of the torus
        const tubularSegments = 64; // Number of segments around the tube
        const ringChainTexture = new THREE.TorusGeometry(radius, tubeRadius, radialSegments, tubularSegments);
        const ringChainMaterial = new THREE.MeshStandardMaterial({
            color: getRingColorByColor(selectedMaterial),  // Lighter gold color
            metalness: 1,
            roughness: 0.1, // Reduced roughness for more specular reflections
        });
        const chainRing = new THREE.Mesh(ringChainTexture, ringChainMaterial)
        chainRing.rotation.set(rotation.x, rotation.y, rotation.z)
        chainRing.position.set(position.x + scale, position.y + scale, position.z)
        chainRing.scale.set(scale, scale, scale)
        return chainRing
    }

    const createLeftChain = (leftCirclePosition: any, chainTexture: any) => {
        const originalWidth = leftChainSizeOriginal.w;
        const originalHeight = leftChainSizeOriginal.h;
        const topLeftPos = neckLeftPos;
        const bottomRightPos = leftCirclePosition;
        const distanceHeight = topLeftPos.y - bottomRightPos.y
        const distanceWidth = - topLeftPos.x + bottomRightPos.x
        const rectangleMaterial = new THREE.MeshBasicMaterial({ map: chainTexture }) //new THREE.MeshBasicMaterial({color: 'red'})//new THREE.MeshBasicMaterial({ map: nameplate.color == NameplateColor.SILVER ? assets?.leftSilverChainTexture : nameplate.color == NameplateColor.GOLD ? assets?.leftGoldChainTexture : nameplate.color == NameplateColor.WHITE ? assets?.leftWhiteGoldChainTexture : assets?.leftSilverChainTexture, transparent: true })
        rectangleMaterial.transparent = true
        const rectangleGeometry = new THREE.PlaneGeometry(originalWidth, originalHeight);
        const chainMesh = new THREE.Mesh(rectangleGeometry, rectangleMaterial);
        const scaleX = distanceWidth / originalWidth
        const scaleY = distanceHeight / originalHeight
        chainMesh.scale.set(scaleX, scaleY, 2)
        chainMesh.position.set(neckLeftPos.x + (scaleX / 2) * originalWidth - originalWidth / 2, neckLeftPos.y - (scaleY / 2) * originalHeight + originalHeight / 2, 0)
        return chainMesh
    }

    const createRightChain = (rightCirclePosition: any, chainTexture: any) => {
        const rightChainMaterial = new THREE.MeshBasicMaterial({ map: chainTexture }) //new THREE.MeshBasicMaterial({color: 'red'})//new THREE.MeshBasicMaterial({ map: nameplate.color == NameplateColor.SILVER ? assets?.rightSilverChainTexture : nameplate.color == NameplateColor.GOLD ? assets?.rightGoldChainTexture : nameplate.color == NameplateColor.WHITE ? assets?.rightWhiteGoldChainTexture : assets?.rightSilverChainTexture, transparent: true }) // new MeshBasicMaterial({color: 'red'})//
        rightChainMaterial.transparent = true
        const rightChainGeometry = new THREE.PlaneGeometry(rightChainSizeOriginal.w, rightChainSizeOriginal.h);
        const rightChainMesh = new THREE.Mesh(rightChainGeometry, rightChainMaterial);
        const rightChainScale = { x: (neckRightPos.x - rightCirclePosition.x) / rightChainSizeOriginal.w, y: (neckRightPos.y - rightCirclePosition.y) / rightChainSizeOriginal.h, z: 1 }
        const newRightChainSize = { w: rightChainSizeOriginal.w * (rightChainScale.x), h: rightChainSizeOriginal.h * (rightChainScale.y) }
        rightChainMesh.position.set(rightCirclePosition.x + newRightChainSize.w / 2 + 0.2, neckRightPos.y - newRightChainSize.h / 2, 0)
        rightChainMesh.scale.set(rightChainScale.x, rightChainScale.y, rightChainScale.z)
        return rightChainMesh
    }

    function getNameplateColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return 0xfecc86
        } else if (color == NameplateMaterialType.SILVER) {
            return 0xe3e3e3
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return 0xf2cbba
        }
        return 0xeeede7
    }


    const createTextGroup = async (fontModel: Font) => {
        nameplatePosition.y = chainSize == 16 ? 35 : 30
        const innerRadius = 2;
        const outerRadius = 4;

        const textGeometry = new TextGeometry(text, {
            font: fontModel,
            size: fontSize / 1.1,
            depth: (1.1 * 3) / (4 * 1.1),
            curveSegments: 32, // Smoothness
        });

        textGeometry.computeBoundingBox();
        textGeometry.center();

        const textMaterial = new THREE.MeshStandardMaterial({
            color: getNameplateColor(selectedMaterial),
            metalness: 1,
            roughness: 0.1,
        });

        const textMesh = new THREE.Mesh(textGeometry, textMaterial)

        textMesh.position.set(nameplatePosition.x, nameplatePosition.y, 0.25)
        // textMesh.rotation.y = Math.PI

        const box = new THREE.Box3().setFromObject(textMesh);
        const size = box.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
        const width = size.x - 1;
        const height = size.y;

        const ringShape = new THREE.Shape();
        ringShape.absarc(0, 0, outerRadius, 0, Math.PI * 2, false);
        const holePath = new THREE.Path();
        holePath.absarc(0, 0, innerRadius, 0, Math.PI * 2, true);
        ringShape.holes.push(holePath);

        const extrudeSettings = {
            depth: 3, // Thickness of the ring
            curveSegments: 12
        };

        const lastLetter = text[text.length - 1]
        const firstLetter = text[0]

        const firstLetterTextGeometry = new TextGeometry(firstLetter, {
            font: fontModel,
            size: fontSize/1.1,
            depth: 1,
            curveSegments: 1,
        });

        const firstLetterMesh = new THREE.Mesh(firstLetterTextGeometry, textMaterial)
        const firstLetterBox = new THREE.Box3().setFromObject(firstLetterMesh);
        const firstLetterSize = firstLetterBox.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
        const firstLetterWidth = firstLetterSize.x;
        const firstLetterHeight = firstLetterSize.y;

        const lastLetterTextGeometry = new TextGeometry(lastLetter, {
            font: fontModel,
            size: fontSize/1.1,
            depth: 1,
            curveSegments: 1,
        });

        const lastLetterMesh = new THREE.Mesh(lastLetterTextGeometry, textMaterial)
        const lastLetterBox = new THREE.Box3().setFromObject(lastLetterMesh);
        const lastLetterSize = lastLetterBox.getSize(new THREE.Vector3()); // Result is a Vector3 with width, height, depth
        const lastLetterWidth = lastLetterSize.x;
        const lastLetterHeight = lastLetterSize.y;

        
        const fetchResponse = await fetch('/fonts/fonts_centers.json')
        const fetchJson = await fetchResponse.json()
        const selectedFont = fetchJson[font]
        selectedFontRef.current = selectedFont
        
        const ringGeometry = new THREE.ExtrudeGeometry(ringShape, extrudeSettings);

        let newSize = fontSize
        if (newSize < 4.75) {
            const diff = 4.75 - newSize
            newSize = 4.75 + diff
        } else if (newSize > 4.75) {
            const diff = newSize - 4.75
            newSize = 4.75 - diff
        }

        const ringScale = 0.15 * 1/(newSize/4.5)
        const pixelLetterKoef = 24.61 * (newSize / 4.75)
        const fontsKoef = 1 / (pixelLetterKoef)
        const lastCenterX = 250//selectedFont[lastLetter]["imageCenter"]["centerX"]
        const lastCenterY = 250//fontData[lastLetter]["imageCenter"]["centerY"]

        const xRightRing = (selectedFont[lastLetter]["green"]["centerX"] - lastCenterX) * (fontsKoef)
        const yRightRing = (lastCenterY - selectedFont[lastLetter]["blue"]["centerY"]) * (fontsKoef)
        const xLeftRing = (selectedFont[firstLetter]["red"]["centerX"] - selectedFont[firstLetter]["imageCenter"]["centerX"]) * (fontsKoef)
        const yLeftRing = (selectedFont[firstLetter]["imageCenter"]["centerY"] - selectedFont[firstLetter]["red"]["centerY"]) * (fontsKoef)


        const leftTextRingPosition = {
            x: -width / 2 + nameplatePosition.x + firstLetterWidth / 2 + xLeftRing - 0.45,
            y: nameplatePosition.y + yLeftRing - (height - firstLetterHeight) / 2
        }

        const rightTextRingPosition = {
            x: width / 2 + nameplatePosition.x - lastLetterWidth / 2 + xRightRing,
            y: nameplatePosition.y + yRightRing// - (height - lastLetterHeight)/2
        }


        const leftRingMesh = new THREE.Mesh(ringGeometry, textMaterial)
        leftRingMesh.scale.set(ringScale, ringScale, ringScale)

        leftRingMesh.position.set(leftTextRingPosition.x, leftTextRingPosition.y, -0.25)
        leftRingMesh.material = textMaterial

        
        const rightRingMesh = new THREE.Mesh(ringGeometry, textMaterial)
        rightRingMesh.position.set(rightTextRingPosition.x, rightTextRingPosition.y, -0.25)
        rightRingMesh.scale.set(ringScale, ringScale, ringScale)
        rightRingMesh.material = textMaterial

        return {
            textMesh,
            leftRingMesh,
            rightRingMesh,
            leftTextRingPosition,
            rightTextRingPosition
        }
    }

    function getScenePositionByColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return new Position(-16, 6, -3)
        } else if (color == NameplateMaterialType.SILVER) {
            return new Position(-1, 2, -4)
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return new Position(-19, 4, -9)
        }
        // else if (color == NameplateMaterialType.ROSE_GOLD) {
        //   return new Position(-19, 4, -9)
        // }
        return new Position(0, 0, 0)
    }

    function getLightColorByColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return 0xffffff
        } else if (color == NameplateMaterialType.SILVER) {
            return 0xdbdbdb
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return 0xffffff
        }
        // else if (color == NameplateMaterialType.ROSE_GOLD) {
        //   return 0xfcfcfc
        // }
        return 0xdbdbdb
    }



    class Position {
        x = 0
        y = 0
        z = 0

        constructor(x: number, y: number, z: number) {
            this.x = x;
            this.y = y;
            this.z = z;
        }
    }

    function getLightPositionByColor(color: NameplateMaterialType) {
        if (color == NameplateMaterialType.GOLD) {
            return new Position(-20, 6, 252)
        } else if (color == NameplateMaterialType.SILVER) {
            return new Position(-10, 0, 252)
        } else if (color == NameplateMaterialType.ROSE_GOLD) {
            return new Position(-20, 6, 113)
        }
        // else if (color == NameplateMaterialType.ROSE_GOLD) {
        //   return new Position(15, -5, 113)
        // }
        return new Position(0, 0, 0)
    }


    return (
        <div>
            <canvas style={{ position: 'absolute', left: (window.innerWidth - 390) / 2, top: 0 }} ref={canvasRef} />
        </div>
    );
}

