import React from 'react';
import cls from 'classnames';
// import { useDebounce } from 'use-debounce';

import { useMediaQuery } from '@mui/material';

import classes from './TextInput.module.css';

type Props = {
  defaultValue: null | string;
  onChange: (val: string) => void;
  noSpaces?: boolean;
  onlyLetters?: boolean;
  placeholder?: string;
  isLimited?: boolean;
  onClick?: ()=> void;
};

export const TextInput: React.FC<Props> = ({
  defaultValue,
  onChange,
  onClick,
  noSpaces = false,
  onlyLetters = false,
  placeholder,
  isLimited,
}) => {
  const isMobile = useMediaQuery('(max-width: 1023px)');
  const [value, setValue] = React.useState(defaultValue || '');
  // const [debouncedValue] = useDebounce(value, 0);

  React.useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value]);

  React.useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue || '');
    }
  }, [defaultValue]);

  const handleChangeText = (e: React.SyntheticEvent<HTMLInputElement>) => {
    let newValue = e.currentTarget.value || '';

    if (onlyLetters) {
      newValue = newValue.replace(/[^a-zA-Z]+/g, '');
    }
    if (noSpaces) {
      newValue = newValue.replace(/\s/g, '');
    }

    if (isLimited && newValue.length > value.length) {
      return;
    }

    setValue(newValue);
  };

  return (
    <div className={cls('d-flex relative pt-4', !isMobile ? 'mb-4' : 'mb-3')}>
      <input
        type='text'
        style={{boxShadow: '0px 11px 16px 0px rgba(38,50,83,0.11)'}}
        className={cls(classes.TextInput, 'outline-0')}
        placeholder={placeholder}
        value={value}
        spellCheck={false}
        onChange={handleChangeText}
        onClick={onClick}
      />
    </div>
  );
};
