import './Slider.css';
import React, { useState, useRef, useEffect, MouseEvent, TouchEvent } from 'react';
import sliderRectIcon from '@assets/sliderRect.svg';
import thumbBottomIcon from '@assets/thumbBottom.svg';
import { NameplateSizeType, useNameplateSettings } from '@/hooks';
import { preciseRound } from '@/utils/math';
import { getWeightInGrams } from '@/utils/nameplate';



function CustomSlider() {
    const [value, setValue] = useState(0);
    const [price, setPrice] = useState(0);
    const sliderRef = useRef<HTMLDivElement | null>(null);
    const thumbRef = useRef<HTMLDivElement | null>(null);

    const {
        fontSize,
        onChangeFontSize,
        onChangeWidthInMM,
        onChangeHeightInMM,
        onChangeArea,
        onSetPrice,
        selectedMaterial,
        area,
        baseArea,
        widthInMM,
        heightInMM,
        baseHeight,
        basePrice,
        baseWidth,
        materialPrice,
        chainPrice
    } = useNameplateSettings();

    const updateSliderValue = (clientX: number) => {
        if (!sliderRef.current) return;

        const sliderRect = sliderRef.current.getBoundingClientRect();
        let newLeft = clientX - sliderRect.left;

        // Constrain thumb within slider bounds
        newLeft = Math.max(0, Math.min(newLeft, sliderRect.width));
        const newValue = Math.round((newLeft / sliderRect.width) * 100);
        setValue(newValue);


        onChangeFontSize(mapValue(newValue))
        const valueCoef = calculateAreaWithCoefficient(newValue, baseArea)
        const weight = preciseRound(getWeightInGrams(valueCoef, selectedMaterial));
        const newPrice = preciseRound(weight * preciseRound(materialPrice) + preciseRound(chainPrice))
        setPrice(preciseRound(newPrice))

        onChangeArea(valueCoef)


        // onChangeWidthInMM()
        const newDimensions = calculateNewDimensions(valueCoef, baseArea, baseWidth, baseHeight)
        onChangeWidthInMM(newDimensions.newWidth)
        onChangeHeightInMM(newDimensions.newHeight)

        if (thumbRef.current) {
            thumbRef.current.style.left = `${newLeft}px`;
        }
    };

    function calculateNewDimensions(newArea: number, baseArea: number, baseWidth: number, baseHeight: number) {

        // Calculate the scaling factor
        const scaleFactor = Math.sqrt(newArea / baseArea);

        // Calculate new width and height proportionally
        const newWidth = baseWidth * scaleFactor;
        const newHeight = baseHeight * scaleFactor;

        return { newWidth, newHeight };
    }


    function calculateAreaWithCoefficient(input: number, baseArea: number): number {
        const coefficient = 0.76; // Коэффициент изменения на 1 единицу input
        return baseArea + (input - 50) * coefficient;
    }


    function calculatePriceWithCoefficient(input: number, basePrice: number): number {
        const coefficient = 0.3; // Коэффициент изменения на 1 единицу input
        return basePrice + (input - 50) * coefficient;
    }

    function mapValue(input: number): number {
        const min = NameplateSizeType.SMALL;
        const max = NameplateSizeType.LARGE;
        return min + (input / 100) * (max - min);
    } 
    // result = min + (input/100)*(max-min)
    // input = (result - min)/(max-min)

    function reverseMapValue(result: number): number {
        const min = NameplateSizeType.SMALL;
        const max = NameplateSizeType.LARGE;
        return ((result - min) / (max - min)) * 100;
    }

    const handleMove = (event: MouseEvent | TouchEvent) => {
        const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX;
        updateSliderValue(clientX);
    };

    const handleStart = (event: MouseEvent | TouchEvent) => {
        document.addEventListener('mousemove', handleMove as unknown as EventListener);
        document.addEventListener('mouseup', handleEnd as unknown as EventListener);
        document.addEventListener('touchmove', handleMove as unknown as EventListener);
        document.addEventListener('touchend', handleEnd as unknown as EventListener);

        handleMove(event); // Call once to get initial position
    };

    const handleEnd = () => {
        document.removeEventListener('mousemove', handleMove as unknown as EventListener);
        document.removeEventListener('mouseup', handleEnd as unknown as EventListener);
        document.removeEventListener('touchmove', handleMove as unknown as EventListener);
        document.removeEventListener('touchend', handleEnd as unknown as EventListener);
    };


    useEffect(() => {
        const newValue = reverseMapValue(fontSize)
        setValue(newValue)

        const valueCoef = calculateAreaWithCoefficient(newValue, baseArea)
        const weight = preciseRound(getWeightInGrams(valueCoef, selectedMaterial));
        const newPrice2 = preciseRound(weight * preciseRound(materialPrice) + preciseRound(chainPrice))

        onChangeArea(valueCoef)
        // const newPrice = calculatePriceWithCoefficient(newValue, basePrice - preciseRound(chainPrice)) + preciseRound(chainPrice)
        setPrice(preciseRound(newPrice2))
        // console.log("newPrice = ", newPrice)
    }, [basePrice])

    return (
        <div
            style={{
                // position: 'relative',
                display: 'flex',
                width: "100%",
                // backgroundColor: 'green',
                height: 73,
                justifyContent: 'center',
                alignItems: 'flex-end'
            }}
        >
            <div style={{
                position: 'relative',
                width: '302px',
                height: '2px',
                backgroundColor: '#3E4966',
                borderRadius: '2px',
                marginTop: '44px'
            }} ref={sliderRef}>
                <div
                    className="slider-thumb"
                    ref={thumbRef}
                    style={{
                        left: `${(value / 100) * (sliderRef.current?.offsetWidth ?? 0)}px`,
                        marginLeft: -30,
                    }}
                    onMouseDown={handleStart}
                    onTouchStart={handleStart}
                >
                    <div style={{
                        backgroundColor: '#3E4966',
                        width: 60,
                        height: 60,
                        borderRadius: 30,
                        justifyItems: 'center',
                        alignContent: 'center',
                        position: 'relative'
                    }}>
                        <img style={{
                            position: 'absolute',
                            bottom: -6, // Places the image at the bottom of the parent
                            left: '50%',
                            transform: 'translateX(-50%)', // Centers the image horizontally                      
                            width: 22,
                            height: 18,
                        }} src={thumbBottomIcon}></img>
                        <p style={{textAlign: 'center'}}>{price}$</p>
                    </div>
                    <img
                        style={{
                            paddingTop: 9,
                            marginLeft: 24.5
                        }}
                        src={sliderRectIcon}></img>
                </div>
            </div>
        </div>
    );
}

export default CustomSlider;